import appImages from 'components/appImages'
import { UseUserState } from 'context/userContext'
import { AdminRole } from 'data/constant'
import { Button, Card, Image, Nav } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const PageNotFound = () => {
  const { t } = useTranslation(["auth"]);
  const {userData} = UseUserState();
  return (
    <Card className="d-flex align-items-center justify-content-center text-center px-3 py-5 bg-transparent h-100">
        <Image src={appImages.Alert} className="img-fluid errorImg mb-3"></Image>
        <h4 className="fw-bold">{t("auth:opps_page_not_found")}</h4>
        <p>{t("auth:page_no_exists")}</p>
        <Nav.Link as={NavLink} to={userData.role_id===AdminRole ? "/admin/dashboard" : (userData && userData?.slug) ? `/${userData.slug}/dashboard` : "/"}>
          <Button variant="primary">{t("auth:btn_go_back")}</Button>
        </Nav.Link>
      </Card>
  )
}

export default PageNotFound