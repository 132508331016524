import { AxiosResponse } from "axios";
import { request } from "api/index";
import { AuthUser } from "model/auth";

export const CreateSiteApi = async (body: any) => {  
    const response: AxiosResponse<AuthUser> = await request({
      url: "/company/add-sites",
      method: "POST",
      body
    });
    return response;
};

export const getSiteListApi = async (body: any) => {  
  const response: AxiosResponse<AuthUser> = await request({
    url: "/sites-list",
    method: "POST",
    body
  });
  return response;
};

export const deleteSiteAPI = async (body: any) => {  
  const response: AxiosResponse<AuthUser> = await request({
    url: "/company/delete-sites",
    method: "POST",
    body
  });
  return response;
};

export const changeSiteStatusAPI = async (body: any) => {  
  const response: AxiosResponse<AuthUser> = await request({
    url: "/site-status",
    method: "POST",
    body
  });
  return response;
};

export const getSiteDetailApi = async (body: any) => {  
  const response: AxiosResponse<AuthUser> = await request({
    url: `/company/site-detail/${body.id}`,
    method: "GET",
    body
  });
  return response;
};

export const updateSiteDetailApi = async (body: any) => {  
  const response: AxiosResponse<AuthUser> = await request({
    url: `/company/update-sites`,
    method: "POST",
    body
  });
  return response;
};

export const getSitesApi = async () => {  
  const response: AxiosResponse<AuthUser> = await request({
    url: `/site-name-list`,
    method: "GET",
  });
  return response;
};
