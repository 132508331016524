import React, { useState } from "react";
import { NotificationProps } from "model/header/header";
import "components/common/notificationPopup/notificationPopup.scss";
import { Link } from "react-router-dom";
import SectionLoader from "../sectionLoader/sectionLoader";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetNotificationApi, ReadNotificationApi } from "api/notification";
import { AnyNsRecord } from "dns";
import { GetProfileAPI } from "api/profileApis";
import { UseUserState } from "context/userContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "config/i18next";
import { useOuterClick } from "./outerClickHook";

const Notification: React.FC<NotificationProps> = ({
  setIsShowNotification,
}) => {
  const outerDivRef = useOuterClick(() => {
    setIsShowNotification(false);
  });
    const [notificationData, setNotificationData] = useState<AnyNsRecord[]>([]);
  const { setUserData } = UseUserState();
  const { t } = useTranslation("common");

    const { isFetching } = useQuery(["GetNotifications"], () => GetNotificationApi(), {
      keepPreviousData: true,
      onSuccess(res) {
        if(res.status === 200){
          setNotificationData(res?.data?.result);
        }
      },
    });

    const {
      refetch: profileDataRefetch,
    } = useQuery(["ProfileDataAPI"], () => GetProfileAPI(), {
      enabled: false,
      keepPreviousData: true,
      onSuccess(data) {
        if (data?.status === 200) {
          setUserData(data?.data?.result);
        }
        if(i18n.language != data?.data?.result?.language){
          i18n.changeLanguage(data?.data?.result?.language)
        }
      },
      onError: (errorData: any) => {
        toast.error(errorData);
      },
    });

    const {
      mutate: readNotify,
      // isLoading: notifyLoding,
      // error: notifyErrorLog,
  } = useMutation(ReadNotificationApi, {
      onSuccess: (data: any) => {
        if (data?.status === 200) {
            setIsShowNotification(true);
            profileDataRefetch();
          }
      },
      onError: (errorData: any) => {
          console.log(errorData)
      },
  });

    const handleNotificationSee = () => {
      setIsShowNotification(true);
      readNotify();
    }

  // useEffect(() => {
  //   function handleClickOutside(event: MouseEvent) {
  //     if (
  //       notificationRef.current &&
  //       !notificationRef.current.contains(event.target as Node)
  //     ) {
  //       setIsShowNotification(false);
  //     }
  //   }

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [setIsShowNotification]);

//   useEffect(() => {
//   function handleClickOutside(event: MouseEvent) {
//     if (
//       !notificationRef.current
//     ) {
//       setIsShowNotification(false);
//     }
//   }

//   document.addEventListener("mousedown", handleClickOutside);

//   return () => {
//     document.removeEventListener("mousedown", handleClickOutside);
//   };
    // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [setIsShowNotification]);

  

  return (
    <>
      {/* <img className="popup-arrow" src={appImages.Intersect} alt="Hide Password" /> */}
      <div className="card">
        {isFetching ? (
             <SectionLoader />
        ) : (
          <div ref={outerDivRef}>
            <div className="notification-header d-flex align-items-center justify-content-between">
              <p className="mb-0">{t("notifications")} &#40;{notificationData && notificationData?.length}&#41;</p>
              <Link to={'#'}><div onClick={() => handleNotificationSee()}>{t("mark_all_read")}</div></Link>
            </div>
            <ul className="notification-list ps-0 mb-0">
              {notificationData && notificationData?.map((item: any, index: number) => {
                return (
                  <li
                  key={index}
                  className={` ${!item?.read_at ? "pending-notify" : ""
                }`}
                >
                    <p className="mb-0">
                      {item?.data?.[i18n.language]?.title ? item?.data?.[i18n.language]?.title : item?.data?.title} <span>{item?.data?.[i18n.language]?.body ? item?.data?.[i18n.language]?.body : item?.data?.body}</span>
                    </p>
                    <span className="mb-0">{item?.time_ago}</span>
                  </li>
                );
              })}
              {/* <div className="view-all-btn text-center">
                <Button variant="primary" className="btn-sm">View All</Button>
              </div> */}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Notification;
