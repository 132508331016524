import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { ModalProps } from "model/modal/modal";
import CommonModal from "components/common/commonModal/commonModal";
import Textbox from "components/common/form/input/textBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import SubmitLoader from "components/common/submitLoader/submitLoader";
import { toast } from "react-toastify";
import Select from "react-select";
import { AddEditAlarmProps } from "model/alarm/alarm";
import { AddEditAlarmSchema } from "utils/schemaValidations/alarm";
import { AlarmSiteApi, UpdateAlarmApi, getAlarmDetailApi, getAlarmTypeApi } from "api/alarm";
import SectionLoader from "components/common/sectionLoader/sectionLoader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateAlarm: React.FC<ModalProps> = ({
    title,
    show,
    handleClose,
    handleShow,
    type,
    data,
}) => {

  const { t } = useTranslation(["common", "alarm", "validation"]);
  const [selectedAlarmType, setSelectedAlarmType] = useState<any>([])
    const [selectedSite, setSelectedSite] = useState<any>([])
    const [alarmType, setAlarmType] = useState<any>([])
    const [showSite, setShowSite] = useState<boolean>(true)
    const [siteList, setSiteList] = useState<any>([])
    const [editId, setEditId] = useState<number>(0)
    const [saveAndSubmit, setSaveAndSubmit] = useState<boolean>(false);

    const [defaultValues, setDefaultValues] = useState<AddEditAlarmProps>({
        alarm_type_id: "",
        name: "",
        link: "",
        location: "",
        site_id: data ? data.site_id : null,
    });


    const {
        mutate: getAlarmType,
        isLoading: isAlarmTypeLoading,
    } = useMutation(getAlarmTypeApi, {
        onSuccess: (data: any) => {
            if (data?.status === 200) {
                setAlarmType(data.data.result)
            }
        },
        onError: (errorData: any) => {
            console.log(errorData)
        },
    });

    const {
        mutate: getAlarmDetail,
        // isLoading: isAlarmDetailLoading,
    } = useMutation(getAlarmDetailApi, {
        onSuccess: (data: any) => {
            if (data?.status === 200) {
                setDefaultValues({
                    alarm_type_id: data.data.result.alarm_type_id ?? "",
                    name: data.data.result.name ?? "",
                    link: data.data.result.link ?? "",
                    location: data.data.result.location ?? "",
                    site_id: data.data.result.site_id ?? "",
                })
                setSelectedSite(siteList.filter((site: any) => {
                    return Number(site.id) === Number(data.data.result.site_id);
                }))
            }
        },
        onError: (errorData: any) => {
            console.log(errorData)
        },
    });

    useEffect(() => {
        reset(defaultValues)
        if (alarmType && alarmType.length > 0) {
            setSelectedAlarmType(alarmType.filter((site: any) => {
                return Number(site.id) === Number(defaultValues.alarm_type_id);
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues, alarmType])

    const {
        mutate: createAlarm,
        isLoading,
    } = useMutation(AlarmSiteApi, {
        onSuccess: (data: any) => {
            if (data?.status === 200) {
                !saveAndSubmit && handleClose(true)
                toast.success(data.data.message)
                setSaveAndSubmit(false);
                reset(defaultValues);
                setSelectedSite([]);
                setSelectedAlarmType([]);
            }
            if (data?.status === 422) {
                if (typeof data?.data?.message === "string") {
                  toast.error(data?.data?.message);
                } else {
                  data?.data?.message?.forEach((item: any) => {
                    Object.keys(item).forEach((fieldName: any) => {
                      setError(fieldName, {
                        type: "custom",
                        message: item[fieldName][0],
                      });
                    });
                  });
                }
              }
        },
        onError: (errorData: any) => {
            console.log(errorData)
        },
    });

    const {
        mutate: updateAlarm,
        isLoading: isUpdateLoading,
    } = useMutation(UpdateAlarmApi, {
        onSuccess: (data: any) => {
            if (data?.status === 200) {
                toast.success(data.data.message)
                handleClose(true)
            }
            if (data?.status === 422) {
                if (typeof data?.data?.message === "string") {
                  toast.error(data?.data?.message);
                } else {
                  data?.data?.message?.forEach((item: any) => {
                    Object.keys(item).forEach((fieldName: any) => {
                      setError(fieldName, {
                        type: "custom",
                        message: item[fieldName][0],
                      });
                    });
                  });
                }
              }
        },
        onError: (errorData: any) => {
            console.log(errorData)
        },
    });

    const closeModal = () => {
        handleClose(false);
    };

    //Form validation
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
        reset,
    } = useForm<AddEditAlarmProps>({
        resolver: yupResolver(AddEditAlarmSchema),
        defaultValues: defaultValues,
    });

    useEffect(() => {
        getAlarmType()
        if (data && type === "site") {
            // let setData = {
            //     site_id: data.site_id
            // }
            // reset(setData);
            setValue("site_id", data.site_id, { shouldDirty:true })
            clearErrors("site_id") 
            setShowSite(false)
        }
        setSiteList(data && data.siteList ? data.siteList : [])
        if (type !== "" && type === "alarm") {
            const { editAlarmID } = data;
            setEditId(editAlarmID)
            if (editAlarmID > 0) {
                getAlarmDetail({ id: editAlarmID })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, type, setValue, reset, setShowSite])

    //save customer
    const onSubmit = (submitFormdata: any) => {
        if (editId > 0) {
            submitFormdata.id = editId;
            updateAlarm(submitFormdata);
        } else {
            createAlarm(submitFormdata);
        }
    };


    return (
        <CommonModal
            title={title}
            show={show}
            handleClose={closeModal}
            handleShow={handleShow}
            dynamicCLass={"create-alarm-modal"}
        >
            {isAlarmTypeLoading ? <SectionLoader /> :
                <Form onSubmit={handleSubmit(onSubmit)} noValidate >

                    <div className="form-group">
                        <Form.Label htmlFor="site_name">{t("alarm:alarm_type")}</Form.Label>
                        <Select
                            getOptionLabel={(item: any) => item.name}
                            getOptionValue={(item: any) => item.id}
                            id="alarm_type"
                            name="alarm_type"
                            isDisabled={title === t("alarm:edit_alarm")}
                            options={alarmType ?? []}
                            isSearchable
                            onChange={(option) => {
                                setValue("alarm_type_id", option.id ? option.id : "", { shouldDirty: true })
                                setSelectedAlarmType(option)
                                clearErrors('alarm_type_id');
                            }}
                            value={selectedAlarmType}
                            placeholder={t("alarm:select_alarm_type")}

                            classNamePrefix="react-select"
                            className="react-select-container"
                        />
                        {errors.alarm_type_id && errors.alarm_type_id?.message && <div className="invalid-feedback d-block">{(errors.alarm_type_id.type === "custom" ? errors.alarm_type_id?.message : t(`validation:${errors.alarm_type_id?.message}`))}</div >}
                    </div>

                    <div className="form-group">
                        <Form.Label htmlFor="name">{t("common:name")}</Form.Label>
                        <Textbox
                            control={control}
                            type="text"
                            id="name"
                            name="name"
                            value=""
                            className="form control"
                            placeholder={t("common:enter_name")}
                            autoComplete="off"
                            max={100}
                            errorMessage={errors.name && (errors.name.type === "custom" ? errors.name?.message : t(`validation:${errors.name?.message}`))}
                        />
                    </div>
                    <div className="form-group">
                        <Form.Label htmlFor="link">{t("alarm:link_id")}</Form.Label>
                        <Textbox
                            control={control}
                            type="text"
                            id="link"
                            name="link"
                            value=""
                            className="form control"
                            placeholder={t("alarm:enter_link")}
                            autoComplete="off"
                            max={150}
                            errorMessage={errors.link && (errors.link.type === "custom" ? errors.link?.message : t(`validation:${errors.link?.message}`))}
                        />
                    </div>
                    <div className="form-group">
                        <Form.Label htmlFor="location">{t("common:location")}</Form.Label>
                        <Textbox
                            control={control}
                            type="text"
                            id="location"
                            name="location"
                            value=""
                            className="form control"
                            placeholder={t("alarm:enter_location")}
                            autoComplete="off"
                            max={250}
                            errorMessage={errors.location && (errors.location.type === "custom" ? errors.location?.message : t(`validation:${errors.location?.message}`))}
                        />
                    </div>
                    {showSite &&
                        <div className="form-group menuplacement-top">
                            <label>{t("common:site")}</label>


                            <Select
                                getOptionLabel={(item: any) => item.sites_name}
                                getOptionValue={(item: any) => item.id}
                                id="site"
                                name="site"
                                options={siteList ?? []}
                                isSearchable
                                onChange={(option) => {
                                    setValue("site_id", option.id ? option.id : "", { shouldDirty: true })
                                    setSelectedSite(option)
                                    clearErrors('site_id');
                                }}
                                value={selectedSite}
                                placeholder={t("supervisor:select_site_name")}
                                classNamePrefix="react-select"
                                className="react-select-container"
                                menuPlacement="top"
                            />

                            {errors.site_id && errors.site_id?.message && <div className="invalid-feedback d-block">{(errors.site_id.type === "custom" ? errors.site_id?.message : t(`validation:${errors.site_id?.message}`))}</div >}

                        </div>
                    }
                    <div className="d-flex align-items-center justify-content-between">
                        {!(title === t("alarm:edit_alarm")) &&
                    <Link to="#" className="btn btn-back blue-color" onClick={()=>{                        
                        handleSubmit(onSubmit)();
                        setSaveAndSubmit(true)
                    }}>
                        {t("alarm:save_add_next_alarm")}
                    </Link>
                }
                    <Button type="submit" className={(title === t("alarm:edit_alarm")) ? "w-100" : ''} variant="primary" disabled={isLoading || isUpdateLoading}>
                        {isLoading || isUpdateLoading ? <SubmitLoader /> : t("common:btn_save")}
                    </Button>
                </div>
                </Form>
            }
        </CommonModal >
    );
};

export default CreateAlarm;
