import React, { useEffect, useState } from "react";
import appImages from "components/appImages";
import { Button, Form, Image, Nav, Table } from "react-bootstrap";
import "components/customer/customer.scss";
import { getSitesApi } from "api/site";
import Select from "react-select";
import CreateAlarm from "./create";
import { deleteAlarmApi, getAlarmListApi } from "api/alarm";
import SectionLoader from "components/common/sectionLoader/sectionLoader";
import { useMutation } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import ConfirmPopup from "components/common/confirmPopup/confirmPopup";
import "components/common/dashboardTable/dashboardTable.scss";
import "components/alarms/alarms.scss";
import { CompanyOwnerRole } from "data/constant";
import { UseUserState } from "context/userContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type AlarmName = 'Fire Alarm' | 'Water Pump' | 'CCTV' | 'Water Tank Level' | 'Access Door' | 'Emergency Push Button';

const AlarmList = () => {
  const { t } = useTranslation(["common", "alarm"]);
  const { userData } = UseUserState()
  const [showAlarmModal, setShowAlarmModal] = useState<boolean>(false);
  const [alarmList, setAlarmList] = useState<any>([]);
  const [siteList, setsiteList] = useState<any>([]);
  const [selectedSite, setSelectedSite] = useState<any>([]);
  const [deleteAlarmID, setDeleteAlarmID] = useState<number>(0);
  const [editAlarmID, setEditAlarmID] = useState<number>(0);
  const [showColumn, setShowColumn] = React.useState(false);

  const getAlarmData = (field: any) => {
    switch (field) {
      case "ID":
        return "link"
        case "Name":
          return "name"
          case "Location":
          return "location"
          case "Site":
          return "site_info"
          case "Status":
          return "status"
          case "Pressure":
          return "pressure"
          case "Alarm":
          return "alarm"
          case "Door":
          return "status"
          case "Battery":
          return "battery"
          case "Electricity":
          return "electricity"
          case "Water Level":
          return "water_level"
      default:
        return "";
    }
  };

  useEffect(() => {
    setShowColumn((userData && userData?.role_id === CompanyOwnerRole))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.role_id])

  const getAlarmListData = () => {
    const data = {
      id: selectedSite && selectedSite?.id ? selectedSite.id : "",
    };
    if (selectedSite && selectedSite?.id) {
      getAlarmList(data);
    }
  };

  useEffect(() => {
    getSitesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAlarmListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite, userData.unread_notification]);

  const {
    mutate: getAlarmList,
    isLoading,
    // error: ErrorLog,
  } = useMutation(getAlarmListApi, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        setAlarmList(data.data.result);
      }
    },
    onError: (errorData: any) => {
      console.log(errorData);
    },
  });

  const {
    mutate: getSitesList,
    isLoading: siteLoding,
    // error: siteErrorLog,
  } = useMutation(getSitesApi, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        setsiteList(data.data.result);
        setSelectedSite(data.data.result[0]);
      }
    },
    onError: (errorData: any) => {
      console.log(errorData);
    },
  });

  const {
    mutate: deleteAlarm,
    isLoading: deleteLoding,
    // error: deleteErrorLog,
  } = useMutation(deleteAlarmApi, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        toast.success(data.data.message)
        getAlarmListData();
        setDeleteAlarmID(0);
      }
      if (data?.status === 422) {
        toast.error(data.data.message)
    }
    },
    onError: (errorData: any) => {
      console.log(errorData);
    },
  });

  const deleteSiteRecord = () => {
    deleteAlarm({ id: deleteAlarmID });
  };

  const openModal = () => setShowAlarmModal(true);

  const closeModal = (status: boolean) => {
    setShowAlarmModal(false);
    setEditAlarmID(0);
    if (status) {
      getAlarmListData();
    }
  };

  const fieldArraysByAlarmName: Record<AlarmName, string[]> = {
    // 'Fire Alarm': ['icon', 'ID', 'Name', 'Location', 'Site', 'Alarm', 'Battery', 'Door', 'Electricity'],
    'Fire Alarm': ['icon', 'ID', 'Name', 'Location', 'Site', 'Alarm', 'Battery', 'Door'],
    // 'Water Pump': ['icon', 'ID', 'Name', 'Location', 'Site', 'Pressure', 'Status', 'Electricity'],
    'Water Pump': ['icon', 'ID', 'Name', 'Location', 'Site', 'Pressure', 'Status'],
    'CCTV': ['icon', 'ID', 'Name', 'Location', 'Site', 'Status'],
    'Water Tank Level': ['icon', 'ID', 'Name', 'Location', 'Site', 'Water Level'],
    'Access Door': ['icon', 'ID', 'Name', 'Location', 'Site', 'Status'],
    'Emergency Push Button': ['icon', 'ID', 'Name', 'Location', 'Site', 'Status'],
  };

  return (
    <>
      <div className="main-heading d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start">
          <h2 className="mx-3">
            {t("common:alarms")}{" "}
            <span>
              {alarmList && alarmList.alarm_count ? alarmList.alarm_count : 0}
            </span>
          </h2>
          <div className="d-flex align-items-center react-select-wrapper">
            <span className="card-heading mb-0">{t("common:site")}: </span>
            <Form className="react-select-input">
              <Select
                getOptionLabel={(item: any) => item.sites_name}
                getOptionValue={(item: any) => item.id}
                id="site"
                name="site"
                options={siteList}
                isSearchable  
                isDisabled={siteLoding}
                onChange={(option) => {
                  setSelectedSite(option);
                }}
                value={selectedSite}
                placeholder={t("common:select_site")}
                classNamePrefix="react-select"
                className="react-select-container"
              />
            </Form>
          </div>
        </div>
        {showColumn && (
          <Button
            variant="primary"
            className="btn-sm"
            onClick={() => setShowAlarmModal(true)}
          >
            <img
              className="btn-img-left"
              src={appImages.AddIcon}
              alt="Hide Password"
            />{" "}
            {t("common:btn_add_new")}
          </Button>
        )}
      </div>
      {isLoading || siteLoding ? (
        <SectionLoader />
      ) : (
        <div className="alarm-section">
        {alarmList &&
          alarmList.alarm_info &&
          alarmList.alarm_info.length > 0 ?
          alarmList.alarm_info.map((alarm: any, index: number) => {
            return (
              <div className="card" key={index}>
                <div className="custom-table">
                  <Table responsive className="mb-0">
                    <thead>
                      <tr key={index}>
                        {fieldArraysByAlarmName[alarm?.name as AlarmName].map((field, fieldIndex) => (
                          <th key={fieldIndex} style={{ padding: '5px', maxWidth: "120px", minWidth: "120px"}}>
                            {field === 'icon' ? (
                              alarm?.name ?? "-"
                             ) :
                              (
                                field ?? ""
                              )
                            }
                          </th>
                        ))}
                        {showColumn && <th style={{ width: '120px' }}>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {alarm.data &&
                        alarm.data.length > 0 ?
                        alarm.data.map((record: any, ind: number) => {
                          return (
                            <tr key={`${index}-${ind}`}>
                              {fieldArraysByAlarmName[alarm.name as AlarmName].map((field, fieldIndex) => (
                                <>
                                <td key={fieldIndex} style={{ padding: '5px', maxWidth: "120px", minWidth: "120px" }}>
                                {field === 'icon' ? (
                                    <Image src={record?.alarm_type?.icon_url} alt={alarm.name} />

                                    ) : (
                                      field === 'Site' ? (
                                        record[getAlarmData(field)]?.sites_name ?? "-"
                                      ) : (
                                        record[getAlarmData(field)]===null ? "-" : record[getAlarmData(field)].length>0 ? record[getAlarmData(field)] : "-"
                                      )
                                    )}
                                </td>
                                    </>
                              ))}
                              {showColumn && (
                                <td  style={{ width: '120px', padding: '5px' }}>
                                  <div className="action-btn">
                                    <Nav.Link
                                      as={NavLink}
                                      to={"#"}
                                      onClick={() => {
                                        setShowAlarmModal(true);
                                        setEditAlarmID(record.id);
                                      }}
                                    >
                                      <Image
                                        src={appImages.EditIcon}
                                        alt="edit"
                                      />
                                    </Nav.Link>
                                    <button
                                      className="bg-transparent border-0"
                                      onClick={() =>
                                        setDeleteAlarmID(record.id)
                                      }
                                    >
                                      <Image
                                        src={appImages.DeleteIcon}
                                        alt="delete"
                                      />
                                    </button>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })
                        : t("common:no_data_found")}
                    </tbody>
                  </Table>
                </div>
              </div>
            );
          })
          : <div className="card">{t("common:no_data_found")}</div>
        }
      </div>
      )}

      {showAlarmModal && (
        <CreateAlarm
          title={editAlarmID > 0 ? t("alarm:edit_alarm") : t("alarm:add_new_alarm")}
          show={showAlarmModal}
          handleClose={closeModal}
          handleShow={openModal}
          type={editAlarmID > 0 ? "alarm" : ""}
          data={{ siteList, editAlarmID }}
        />
      )}
      {deleteAlarmID > 0 && (
        <ConfirmPopup
          show={true}
          handleClose={() => {
            setDeleteAlarmID(0);
          }}
          onDelete={deleteSiteRecord}
          isLoading={deleteLoding}
        />
      )}
    </>
  );
};

export default AlarmList;
