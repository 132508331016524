"use client";
import React, { Suspense, useEffect, useState } from "react";
import { Button, Form, Image, Navbar, Stack } from "react-bootstrap";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import appImages from "components/appImages";
import { AdminRole, NavItems } from "data/constant";
import "components/common/authHeader/authHeader.scss";
import Footer from "../footer/footer";
import Notification from "components/common/notificationPopup/notificationPopup";
import { UseUserState } from "context/userContext";
import SectionLoader from "../sectionLoader/sectionLoader";
import { Highlighter, Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { GlobalSearchRoleBase } from "utils/roleBaseCommon";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { LogOutAPI, changeLanguageApi } from "api/authApis";
import i18n from "config/i18next";
import { useTranslation } from "react-i18next";
import SubmitLoader from "../submitLoader/submitLoader";
import Select, {
  components,
  OptionProps,
  SingleValueProps,
  // StylesConfig,
} from "react-select";
import { getAccesToken, getLocalSlug } from "utils/localstorage";
interface AuthHeaderProps {
  path: string;
  name: string;
}
interface Country {
  value: string;
  label: string;
}

const countries: Country[] = [
  { value: "en", label: "English" },
  { value: "ar", label: "Arabic" },
];

const Option: React.FC<OptionProps<Country>> = (props) => (
  <components.Option {...props} className="country-option">
    {props.data.label}
  </components.Option>
);

interface SingleValuePropsWithIcon extends SingleValueProps<Country> {
  data: Country;
}

const SingleValue: React.FC<SingleValuePropsWithIcon> = ({
  children,
  ...props
}) => (
  <components.SingleValue {...props}>
    <img src={appImages.GlobalIcon} alt="global" className="global-logo" />
    {"  "}{children}
  </components.SingleValue>
);

const AuthHeader: React.FC<AuthHeaderProps> = ({ path, name }) => {
  const { userData } = UseUserState();
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
  const [selectedSearch, setSelectedSearch] = useState<any[]>([]);
  const [pendingNotify, setPendingNotify] = useState<number | string>();
  const { t } = useTranslation(["common"]);
  const currentLang = localStorage.getItem("i18nextLng")!=null ? localStorage.getItem("i18nextLng") : 'en';
  const selectCountry = countries.find((item: any) => item.value === currentLang);
  const [selectedCountry, setSelectedCountry] = useState<any>(selectCountry);
  useEffect(() => {
  const selectCountry = countries.find((item: any) => item.value === userData.language);
  setSelectedCountry(selectCountry)
  }, [userData])

  const {
    mutate: changeLanguage,
    isLoading: isChangeLangLoading,
    // error
  } = useMutation(changeLanguageApi, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        i18n.changeLanguage(data.data.result.language);
        toast.success(data.data.result.message);
        // window.location.reload();
      }
      if (data?.status === 422) {
        toast.error(data.data.message);
      }
    },
    onError: (errorData: any) => {
      toast.error(errorData);
    },
  });

  const handleChange = (lng: any) => {
    setSelectedCountry(lng);
    changeLanguage({language: lng.value})
  };
  const filteredOptions = countries.filter((option) => option.value !== (selectedCountry?.value || ""));

  useEffect(() => {
    if (userData) {
      setPendingNotify(userData.unread_notification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    mutate: LogoutApi,
    isLoading: isLogoutLoading,
    // error: mutateError,
  } = useMutation(LogOutAPI, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
      }
      if (data?.status === 422) {
        toast.error(data.data.message);
      }
      localStorage.removeItem("AuthSecToken");
      navigate(
        `${
          userData.role_id === AdminRole ? `/admin/signin` : `/${userData.slug ? userData.slug : ''}`
        }`
      );
    },
    onError: (errorData: any) => {
      if (typeof errorData === "string") {
        toast.error(errorData);
      }
    },
  });

  const handleLogout = () => {
    const body = {
      device_token: localStorage.getItem("d_token"),
      token: getAccesToken()
    };
    LogoutApi(body);
  };

  useEffect(() => {
    if (userData) {
      const searchData = GlobalSearchRoleBase({ role: userData?.role_id });
      setSelectedSearch(searchData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.role_id]);

  const handleOnSelect = (item: any) => {
    item && item.length > 0 && navigate(userData.role_id === AdminRole ? item[0].url : `/${userData.slug ?? getLocalSlug()}${item[0].url}`);
  };

  const list = NavItems.filter((val) => val?.role.includes(userData?.role_id));

  const { pathname } = location;

  return (
    <div id="wrapper">
      <header>
        <aside id="sidebar-wrapper" style={{ zIndex: 1 }}>
          <div className="sidebar-brand d-flex align-items-center">
            <Image src={appImages.LogoSm} alt="" />
          </div>
          <ul className="sidebar-nav mb-0">
            {list.map((item, i) => {
              const isActive = pathname?.split("/")?.includes(item.path);

              return (
                item.isSidebar && (
                  <li key={i} className={`${isActive ? "active" : ""}`}>
                    <Link
                      to={`${userData.slug ? userData.slug : "admin"}/${
                        item.path
                      }`}
                      className="d-flex align-items-center"
                    >
                      <Image
                        src={isActive ? item.activeIcon : item.icon}
                        height={18}
                        alt=""
                      ></Image>
                      {<span>{t(`common:${item.name}`)}</span>}
                    </Link>
                  </li>
                )
              );
            })}
          </ul>
          <Button
            disabled={isLogoutLoading}
            onClick={() => handleLogout()}
            className="dashboard-logout p-0 border-0 w-auto no-anim"
          >
            {isLogoutLoading && <SubmitLoader />}
            <Image
              src={appImages.LogoutIcon}
              width={20}
              height={20}
              alt=""
            ></Image>
            <span>{t("common:btn_logout")}</span>
          </Button>
        </aside>

        <Navbar
          className="d-flex align-items-center justify-content-between"
          id="navbar-wrapper"
        >
          <div className="navbar-left form-group mb-0">
            <Typeahead
              onChange={(selected) => handleOnSelect(selected)}
              // autoFocus={true}
              options={[...selectedSearch]}
              labelKey={(option: any) => `${option.title}`}
              id="SearchMainmenu"
              minLength={1}
              placeholder={t("common:search")}
              renderMenuItemChildren={(option: any, props: any) => (
                <Stack direction="horizontal" gap={3}>
                  <span className="d-block text-start">
                    <Highlighter search={props.text}>
                      {option.title}
                    </Highlighter>
                    {/* <span className="d-block text-start fst-italic fw-lighter fs-6">
                      {option.path}
                    </span> */}
                  </span>
                </Stack>
              )}
              // selected={selectedSearch}
            />
            {/* <Form className="search-input">
              <div className="form-group mb-0">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <Image src={appImages.SearchIcon} alt="" />
              </div>
            </Form> */}
          </div>
          <div className="navbar-right d-flex align-items-center">
            <div className="d-flex align-items-center react-select-wrapper">
              <Form className="react-select-input">
                {/* <Select
                  getOptionLabel={(item: any) => item.sites_name}
                  getOptionValue={(item: any) => item.id}
                  id="site"
                  name="site"
                  // options={siteList}
                  // isSearchable
                  // isDisabled={siteLoding}
                  // value={selectedSite}
                  placeholder="Language"
                  classNamePrefix="react-select"
                  className="react-select-container"
                /> */}
                <Select
                  value={selectedCountry}
                  options={filteredOptions}
                  onChange={handleChange}
                  isLoading={isChangeLangLoading}
                  // styles={customStyles}
                  components={{
                    Option,
                    SingleValue,
                  }}
                  isSearchable={false}
                  classNamePrefix="react-select"
                  className="react-select-container"
                />
              </Form>
            </div>
            <div
              className="nav-notification position-relative nav-item cursor-pointer"
              onClick={() => setIsShowNotification(!isShowNotification)}
            >
              {userData.role_id !== AdminRole  &&
                <div className="position-relative">
                  <Image src={appImages.NotificationIcon} alt="" />
                  <div
                    className="badge-bell"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {pendingNotify}
                  </div>
                </div>
              }
              {isShowNotification && (
                <div
                  // className={`${
                  //   isShowNotification === true ? "notification-open" : ""
                  // } notification-section `}
                  className="notification-open"
                >
                  <Notification setIsShowNotification={setIsShowNotification} />
                </div>
              )}
            </div>
            <div className="user-profile nav-item d-flex align-items-center">
              <div
                className="profile-img"
                style={{
                  cursor: "pointer",
                  backgroundImage: `url(${userData?.profile_image_url})`,
                }}
                onClick={() =>
                  navigate(
                    userData.role_id === AdminRole
                      ? "/admin/profile"
                      : `/${userData?.slug}/profile`
                  )
                }
              ></div>
              <div className="nav-user-detail">
                <h4>
                  {userData?.role_id === AdminRole
                    ? t("common:admin")
                    : userData?.name ?? ""}
                </h4>
                {userData?.role_id === AdminRole && (
                  <span>{t("common:super_admin")}</span>
                )}
              </div>
            </div>
            {/* <div>
             <Button onClick={() => handleLanChange()}>Change Lang</Button> 
            </div> */}
          </div>
        </Navbar>
      </header>
      <div className="content-wrapper">
        <Suspense fallback={<SectionLoader />}>
          <Outlet />
        </Suspense>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default AuthHeader;
