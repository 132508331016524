import { AxiosResponse } from "axios";
import { request } from "api/index";
import { AuthUser } from "model/auth";

export const getAlarmTypeApi = async () => {  
    const response: AxiosResponse<AuthUser> = await request({
      url: "/company/alarm-type-list",
      method: "GET",
    });
    return response;
};

export const AlarmSiteApi = async (body: any) => {  
    const response: AxiosResponse<AuthUser> = await request({
      url: "/company/add-alarm",
      method: "POST",
      body
    });
    return response;
};

export const getAlarmListApi = async (body: any) => {  
    const response: AxiosResponse<AuthUser> = await request({
      url: `/alarm-list${body.id ? `/${body.id}` : ``}`,
      method: "GET",
      body
    });
    return response;
};

export const deleteAlarmApi = async (body: any) => {  
    const response: AxiosResponse<AuthUser> = await request({
      url: `/company/delete-alarm`,
      method: "POST",
      body
    });
    return response;
};

export const getAlarmDetailApi = async (body: any) => {  
    const response: AxiosResponse<AuthUser> = await request({
      url: `/company/alarm-detail/${body.id}`,
      method: "GET",
      body
    });
    return response;
};

export const UpdateAlarmApi = async (body: any) => {  
    const response: AxiosResponse<AuthUser> = await request({
      url: `/company/update-alarm`,
      method: "POST",
      body
    });
    return response;
};