import * as Yup from 'yup';
import YupPassword from 'yup-password'
YupPassword(Yup);

export const AddEditSiteSchema = Yup.object().shape({
    site_name: Yup.string().required("site_name_required"),
    address: Yup.string().required("address_required"),        
    longitude: Yup.string()
        .required("longitude_required")
        .matches(/^-?\d+(\.\d+)?$/, "only_number_should_allowed"),
    area_id: Yup.string().required("area_required"),
    latitude: Yup.string()
        .required("latitude_required")
        .matches(/^-?\d+(\.\d+)?$/, "only_number_should_allowed"),
});