import React from "react";
import "components/privacyPolicy/privacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-content container d-flex flex-column gap-3">
        <div className="privacy-section">
          <h3 className="heading">WSTYM Legal Info</h3>
          <p className="content">
            Our Privacy Policy (“Privacy Policy”) helps explain our data
            practices, including the information we process to provide our
            Services.
          </p>
          <p className="content">
            For example, our Privacy Policy talks about what information we
            collect and how this affects you. It also explains the steps we take
            to protect your privacy, like building our Services so delivered
            messages aren’t stored by us and giving you control over who you
            communicate with on our Services.
          </p>
          <p className="content">
            This Privacy Policy applies to all of our Services unless specified
            otherwise.
          </p>
        </div>
        <div className="privacy-section">
          <h3 className="heading">Key Updates</h3>
          <p className="content">
            Respect for your privacy is coded into our DNA. Since we started
            WSTYM, we’ve built our services with a set of strong privacy
            principles in mind. In our updated{" "}
            <a className="text-decoration-none" href="/privacy-policy">
              Privacy Policy
            </a>{" "}
            you’ll find:
          </p>
          <p className="content">
            &#x2022; <b>Additional Information On How We Handle Your Data.</b>{" "}
            Our updated Terms and Privacy Policy provide more information on how
            we process your data, and our commitment to privacy. For example,
            we’ve added more information about more recent product features and
            functionalities, how we process your data for safety, security, and
            integrity, and added more direct links to user settings, Help Center
            articles and how you can manage your information.
          </p>
        </div>

        <div className="privacy-section">
          <h3 className="heading">Information We Collect</h3>
          <p className="content">
            WSTYM must receive or collect some information to operate, provide,
            improve, understand, customize, support, and market our Services,
            including when you install, access, or use our Services.
          </p>
          <p className="content">
            The types of information we receive and collect depend on how you
            use our Services. We require certain information to deliver our
            Services and without this we will not be able to provide our
            Services to you. For example, you must provide your mobile phone
            number to create an account to use our Services.
          </p>
          <p className="content">
            Our Services have optional features which, if used by you, require
            us to collect additional information to provide such features. You
            will be notified of such collection, as appropriate. If you choose
            not to provide the information needed to use a feature, you will be
            unable to use the feature. For example, you cannot share your
            location with your contacts if you do not permit us to collect your
            location data from your device. Permissions can be managed through
            your Settings menu on both Android and iOS devices.
          </p>
          <p className="content">Information You Provide</p>
          <p className="content">
            &#x2022; <b>Your Account Information.</b> You must provide your
            mobile phone number and basic information (including a profile name
            of your choice) to create a WSTYM account. If you don’t provide us
            with this information, you will not be able to create an account to
            use our Services. You can add other information to your account,
            such as a profile picture and “about” information.
          </p>
          <p className="content">
            &#x2022; <b>Your Messages.</b> We do not retain your messages in the
            ordinary course of providing our Services to you. Instead, your
            messages are stored on your device and not typically stored on our
            servers. Once your messages are delivered, they are deleted from our
            servers. The following scenarios describe circumstances where we may
            store your messages in the course of delivering them:
          </p>
          <p className="content">
            &#x2022; <b>Undelivered Messages.</b> If a message cannot be
            delivered immediately (for example, if the recipient is offline), we
            keep it in encrypted form on our servers for up to 30 days as we try
            to deliver it. If a message is still undelivered after 30 days, we
            delete it.
          </p>
          <p className="content">
            &#x2022; <b>Media Forwarding.</b> When a user forwards media within
            a message, we store that media temporarily in encrypted form on our
            servers to aid in more efficient delivery of additional forwards.
          </p>
          <p className="content">
            &#x2022; <b>Your Connections.</b> You can use the contact upload
            feature and provide us, if permitted by applicable laws, with the
            phone numbers in your address book on a regular basis, including
            those of users of our Services and your other contacts. If any of
            your contacts aren’t yet using our Services, we’ll manage this
            information for you in a way that ensures those contacts cannot be
            identified by us. You can create, join, or get added to groups and
            broadcast lists, and such groups and lists get associated with your
            account information. You give your groups a name. You can provide a
            group profile picture or description.
          </p>
          <p className="content">
            &#x2022; <b>Status Information.</b> You may provide us your status
            if you choose to include one on your account.
          </p>
          <p className="content">
            &#x2022; <b>Customer Support And Other Communications.</b> When you
            contact us for customer support or otherwise communicate with us,
            you may provide us with information related to your use of our
            Services, including copies of your messages, any other information
            you deem helpful, and how to contact you (e.g., an email address).
            For example, you may send us an email with information relating to
            app performance or other issues.
          </p>
          <br />
          <p className="content">Automatically Collected Information</p>
          <p className="content">
            &#x2022; <b>Device And Connection Information.</b> We collect device
            and connection-specific information when you install, access, or use
            our Services. This includes information such as hardware model,
            operating system information, battery level, signal strength, app
            version, browser information, mobile network, connection information
            (including phone number, mobile operator or ISP), language and time
            zone, IP address, device operations information, and identifiers.
          </p>
          <p className="content">
            &#x2022; <b>Location Information.</b> We collect and use precise
            location information from your device with your permission when you
            choose to use location-related features. There are certain settings
            relating to location-related information which you can find in your
            device settings or the in-app settings, such as location sharing.
            Even if you do not use our location-related features, we use IP
            addresses and other information like phone number area codes to
            estimate your general location (e.g., city and country). We also use
            your location information for diagnostics and troubleshooting
            purposes.
          </p>
          <p className="content">
            &#x2022; <b>Cookies.</b> We use cookies to operate and provide our
            Services, including to provide our Services that are web-based,
            improve your experiences, understand how our Services are being
            used, and customize them. For example, we use cookies to provide our
            Services for web and desktop and other web-based services. We may
            also use cookies to understand which of our Help Center articles are
            most popular and to show you relevant content related to our
            Services. Additionally, we may use cookies to remember your choices,
            like your language preferences, to provide a safer experience, and
            otherwise to customize our Services for you.
          </p>
        </div>

        <div className="privacy-section">
          <h3 className="heading">Third-Party Information</h3>
          <p className="content">
            &#x2022; <b>Information Others Provide About You.</b> We receive
            information about you from other users. For example, when other
            users you know use our Services, they may provide your phone number,
            name, and other information (like information from their mobile
            address book) just as you may provide theirs. They may also send you
            messages, send messages to groups to which you belong, or call you.
            We require each of these users to have lawful rights to collect,
            use, and share your information before providing any information to
            us.
          </p>
          <p className="content">
            You should keep in mind that in general any user can capture
            screenshots of your chats or messages or make recordings of your
            calls with them and send them to WSTYM or anyone else, or post them
            on another platform.
          </p>
          <p className="content">
            &#x2022; <b>User Reports.</b> Just as you can report other users,
            other users or third parties may also choose to report to us your
            interactions and your messages with them or others on our Services;
            for example, to report possible violations of our Terms or policies.
            When a report is made, we collect information on both the reporting
            user and reported user.
          </p>
          <p className="content">
            &#x2022; <b>Businesses On WSTYM.</b> Businesses you interact with
            using our Services may provide us with information about their
            interactions with you. We require each of these businesses to act in
            accordance with applicable law when providing any information to us.
          </p>
          <p className="content">
            When you message with a business on WSTYM, keep in mind that the
            content you share may be visible to several people in that business.
            In addition, some businesses might be working with third-party
            service providers to help manage their communications with their
            customers. For example, a business may give such third-party service
            provider access to its communications to send, store, read, manage,
            or otherwise process them for the business. To understand how a
            business processes your information, including how it might share
            your information with third parties or Meta, you should review that
            business’ privacy policy or contact the business directly.
          </p>
        </div>

        <div className="privacy-section">
          <h3 className="heading">How We Use Information</h3>
          <p className="content">
            We use information we have (subject to choices you make and
            applicable law) to operate, provide, improve, understand, customize,
            support, and market our Services. Here’s how:
          </p>
          <p className="content">
            &#x2022; <b>Our Services.</b> We use information we have to operate
            and provide our Services, including providing customer support;
            completing tickets or transactions; improving, fixing, and
            customizing our Services; and connecting our Services with Conclouds
            that you may use.
          </p>
          <p className="content">
            &#x2022; <b>Safety, Security, And Integrity.</b> Safety, security,
            and integrity are an integral part of our Services. We use
            information we have to verify accounts and activity; combat harmful
            conduct; protect users against bad experiences and spam; and promote
            safety, security, and integrity on and off our Services, such as by
            investigating suspicious activity or violations of our Terms and
            policies, and to ensure our Services are being used legally.
          </p>
          <p className="content">
            &#x2022; <b>No Third-Party Banner Ads.</b> We still do not allow
            third-party banner ads on our Services. We have no intention to
            introduce them, but if we ever do, we will update this Privacy
            Policy.
          </p>
        </div>

        <div className="privacy-section">
          <h3 className="heading">Information You And We Share</h3>
          <p className="content">
            You share your information as you use and communicate through our
            Services, and we share your information to help us operate, provide,
            improve, understand, customize, support, and market our Services.
          </p>
          <p className="content">
            &#x2022;{" "}
            <b>
              Send Your Information To Those With Whom You Choose To
              Communicate.
            </b>{" "}
            You share your information (including messages) as you use and
            communicate through our Services.
          </p>
          <p className="content">
            &#x2022; <b>Information Associated With Your Account.</b> Your phone
            number, profile name and photo, “about” information, last seen
            information, and message receipts are available to anyone who uses
            our Services, although you can configure your Services settings to
            manage certain information available to other users, including
            businesses, with whom you communicate.
          </p>
        </div>

        <div className="privacy-section">
          <h3 className="heading">Managing And Retaining Your Information</h3>
          <p className="content">
            You can retain your account only through your company’s admin.
          </p>
          <p className="content">
            &#x2022;{" "}
            <b>
              Changing Your Mobile Phone Number, Profile Name And Picture, And
              “About” Information.
            </b>{" "}
            If you change your mobile phone number, you must update it using our
            in-app change number feature and transfer your account to your new
            mobile phone number. You can also change your profile name, profile
            picture, and “about” information at any time.
          </p>
          <p className="content">
            &#x2022; <b>Deleting Your WSTYM Account.</b> Only your company’s
            admin can delete your accouny.
          </p>
        </div>

        <div className="privacy-section">
          <h3 className="heading">Updates To Our Policy</h3>
          <p className="content">
            We may amend or update our Privacy Policy. We will provide you
            notice of amendments to this Privacy Policy, as appropriate, and
            update the “Effective Date” at the top of this Privacy Policy.
            Please review our Privacy Policy from time to time.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
