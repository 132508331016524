import AuthHeader from "components/common/authHeader/authHeader";
import { useLocation } from "react-router-dom";
import "layout/authLayout/authLayout.scss";

function AuthLayout() {
  const location = useLocation();

  // useEffect(() => {
  //     if(!userData.page_loader){
  //         const divSection = rightSideRef.current;
  //         const divSectionStyle= window.getComputedStyle(divSection)
  //         const divSectionPadding = divSectionStyle.getPropertyValue('padding').slice(0,2)
  //         localStorage.setItem('rightSectionPadding', divSectionPadding )
  //     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userData])
  


  return (
    <>
      <div id="auth-layout-section">
        <AuthHeader
          path={location.pathname}
          name={location.pathname.split("/")[1]}
        />
      </div>
    </>
  );
}

export default AuthLayout;
