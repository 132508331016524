import AdminForgotPassword from "components/forgotPassword/adminForgotPassword";
import ForgotPassword from "components/forgotPassword/forgotPassword";
import AdminResetPassword from "components/resetPassword/adminResetPassword";
import ResetPassword from "components/resetPassword/resetPassword";
import AdminSignIn from "components/signin/adminSignin";
import { lazy } from "react";
import SignIn from "components/signin/signin";
import CreatePassword from "components/createPassword/createPassword";
const PageNotFound = lazy(() => import("components/pages/404"));
const MasterLayout = lazy(() => import("../layout/masterLayout/masterLayout"));
const ServerError = lazy(() => import("components/pages/500"));
const BlockPopup = lazy(() => import("components/common/blockPopup/blockPopup"));

const PublicRouteList = {
  path: "/",
  element: <MasterLayout />,
  children: [
    {
      path: '/',
      element: <SignIn />,                
    },
    {
      path: "/:customerName",
      element: <SignIn />,
    },
    {
      path: '/404',
      element: <PageNotFound />,                
    },
    {
      path: '/500',
      element: <ServerError />,                
    },
    {
      path: '/access-denied',
      element: <BlockPopup />,                
    },
    {
      path: "/admin/signin",
      element: <AdminSignIn />,
    },
    {
      path: "/admin/forgot-password",
      element: <AdminForgotPassword />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/:slug/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password-admin/:email/:token",
      element: <AdminResetPassword />,
    },
    {
      path: "/:slug/reset-password/:email/:token",
      element: <ResetPassword />,
    },
    {
      path: "/:slug/create-password/:email/:token",
      element: <CreatePassword />,
    },
  ],
};

export default PublicRouteList;
