import i18n from 'i18next';

import common from 'locales/en/common.json';
import admin_dashboard from 'locales/en/admin_dashboard.json';
import auth from 'locales/en/auth.json';
import customer from 'locales/en/customer.json';
import profile from 'locales/en/profile.json';
import companyOwnerDashboard from 'locales/en/companyOwner_dashboard.json';
import alarm from 'locales/en/alarm.json';
import area from 'locales/en/area.json';
import site from 'locales/en/site.json';
import supervisor from 'locales/en/supervisor.json';
import floorManager from 'locales/en/floorManager.json';
import cctv from 'locales/en/cctv.json';
import supportTicket from 'locales/en/supportTicket.json';
import validation from 'locales/en/validation.json';
import settings from 'locales/en/settings.json';

// import HOME_DE from 'locales/de/home.json';
import common_AR from 'locales/ar/common.json';
import admin_dashboard_AR from 'locales/ar/admin_dashboard.json';
import auth_AR from 'locales/ar/auth.json';
import customer_AR from 'locales/ar/customer.json';
import profile_AR from 'locales/ar/profile.json';
import companyOwnerDashboard_AR from 'locales/ar/companyOwner_dashboard.json';
import alarm_AR from 'locales/ar/alarm.json';
import area_AR from 'locales/ar/area.json';
import site_AR from 'locales/ar/site.json';
import supervisor_AR from 'locales/ar/supervisor.json';
import floorManager_AR from 'locales/ar/floorManager.json';
import cctv_AR from 'locales/ar/cctv.json';
import supportTicket_AR from 'locales/ar/supportTicket.json';
import validation_AR from 'locales/ar/validation.json';
import settings_AR from 'locales/ar/settings.json';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
    en: {
        common,
        admin_dashboard,
        auth,
        customer,
        profile,
        companyOwnerDashboard,
        alarm,
        area,
        site,
        supervisor,
        floorManager,
        cctv,
        supportTicket,
        validation,
        settings
    },
    ar: {
        common: common_AR,
        admin_dashboard: admin_dashboard_AR,
        auth: auth_AR,
        customer: customer_AR,
        profile: profile_AR,
        companyOwnerDashboard: companyOwnerDashboard_AR,
        alarm: alarm_AR,
        area: area_AR,
        site: site_AR,
        supervisor: supervisor_AR,
        floorManager: floorManager_AR,
        cctv: cctv_AR,
        supportTicket: supportTicket_AR,
        validation: validation_AR,
        settings: settings_AR
    }
}

const currentLang = localStorage.getItem("i18nextLng")!=null ? localStorage.getItem("i18nextLng") : 'en';


i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
    lng: `${currentLang}`,
  
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources
});

export default i18n;
