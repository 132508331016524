import { request } from "api/index";
import { AxiosResponse } from "axios";
import { getCompanyLogoHome, ResetPasswordSubmitSchema } from "model/auth";

export const SignInAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/login",
    method: "POST",
    body,
  });
  return response;
};

export const LogOutAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/logout",
    method: "POST",
    body
  });
  return response;
};

export const ForgotPasswordAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/forgot-password",
    method: "POST",
    body,
  });
  return response;
};

export const ResetPasswordAPI = async (body: ResetPasswordSubmitSchema) => {
  const response: AxiosResponse<any> = await request({
    url: "/reset-password",
    method: "POST",
    body,
  });
  return response;
};

export const getLogo = async (body: getCompanyLogoHome) => {
  const response: AxiosResponse<any> = await request({
    url: "/get-company-logo-home",
    method: "POST",
    body,
  });
  return response;
};

export const changeLanguageApi = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/language-change",
    method: "POST",
    body,
  });
  return response;
};