import { Button, Form } from "react-bootstrap";
import { ModalProps } from "model/modal/modal";
import CommonModal from "components/common/commonModal/commonModal";
import SubmitLoader from "../submitLoader/submitLoader";
import { useTranslation } from "react-i18next";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { useQuery } from "@tanstack/react-query";

const ConfirmPopup: React.FC<ModalProps> = ({
  handleClose,
  onDelete,
  isLoading=false,
  message="",
  type="delete",
}) => {
  const { t } = useTranslation(["common"]);
  const closeModal = () => {
    handleClose(false);
  };

  return (
    <CommonModal
      title={t("common:are_you_sure")}
      show={true}
      handleClose={closeModal}      
      dynamicCLass={"delete-modal"}
    >
      <Form noValidate>
        <p className="modal-des text-center">{message ? message : t("common:delete_msg")}</p>
        <div className="d-flex justify-content-center gap-xxl-3 gap-2">
          <Button className="btn-lg btn-outline-primary" onClick={()=>handleClose(false)}>
            {t("common:btn_cancel")}
          </Button>
          <Button
            type="button"
            onClick={onDelete}
            variant="primary"
            className="btn-lg"
            disabled={isLoading}
          >
            {isLoading ? <SubmitLoader /> : type==="confirm" ? t("common:btn_submit") : t("common:btn_delete")}
            
          </Button>
        </div>
      </Form>
    </CommonModal>
  );
};

export default ConfirmPopup;
