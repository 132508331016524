import {
  AdminRole,
  CompanyOwnerRole,
  FloorManagerRole,
  SupervisorRole,
} from "data/constant";

export const GlobalSearchRoleBase = ({ role }: any) => {
  switch (role) {
    case AdminRole:
      return [
        {
          id: "1",
          title: "Dashboard",
          url: '/admin/dashboard',
          text: 'dashboard',
          path: "dashboard",
        },
        {
          id: "2",
          title: "Customer",
          url: '/admin/customer',
          text: 'customer',
          path: "customer",
        },
        {
          id: "3",
          title: "Map",
          url: '/admin/map',
          text: 'map',
          path: "map",
        },
        {
          id: "4",
          title: "Profile",
          url: '/admin/profile',
          text: 'profile',
          path: "profile",
        },
      ];
    case CompanyOwnerRole:
      return [
        {
          id: "1",
          title: "Dashboard",
          url: '/dashboard',
          text: 'dashboard',
          path: "dashboard",
        },
        {
          id: "2",
          title: "Area",
          url: '/area',
          text: 'area',
          path: "area",
        },
        {
          id: "3",
          title: "Site",
          url: '/site',
          text: 'site',
          path: "site",
        },
        {
          id: "4",
          title: "Map",
          url: '/map',
          text: 'map',
          path: "map",
        },
        {
          id: "5",
          title: "Supervisor",
          url: '/supervisor',
          text: 'supervisor',
          path: "supervisor",
        },
        {
          id: "6",
          title: "Site Manager",
          url: '/floor-manager',
          text: 'floor-manager',
          path: "floor-manager",
        },
        {
          id: "7",
          title: "Alarms",
          url: '/alarms',
          text: 'alarms',
          path: "alarms",
        },
        {
          id: "8",
          title: "CCTV",
          url: '/cctv',
          text: 'cctv',
          path: "cctv",
        },
        {
          id: "9",
          title: "Profile",
          url: '/profile',
          text: 'profile',
          path: "profile",
        },
      ];
    case SupervisorRole:
      return [
        {
          id: "1",
          title: "Dashboard",
          url: '/dashboard',
          text: 'dashboard',
          path: "dashboard",
        },
        {
          id: "2",
          title: "Site",
          url: '/site',
          text: 'site',
          path: "site",
        },
        {
          id: "3",
          title: "Map",
          url: '/map',
          text: 'map',
          path: "map",
        },
        {
          id: "4",
          title: "Site Manager",
          url: '/floor-manager',
          text: 'floor-manager',
          path: "floor-manager",
        },
        {
          id: "5",
          title: "Alarms",
          url: '/alarms',
          text: 'alarms',
          path: "alarms",
        },
        {
          id: "6",
          title: "CCTV",
          url: '/cctv',
          text: 'cctv',
          path: "cctv",
        },
        {
          id: "7",
          title: "Support Ticket",
          url: '/support-ticket',
          text: 'support-ticket',
          path: "support-ticket",
        },
        {
          id: "8",
          title: "Profile",
          url: '/profile',
          text: 'profile',
          path: "profile",
        },
      ];
    case FloorManagerRole:
      return [
        {
          id: "1",
          title: "Dashboard",
          url: '/dashboard',
          text: 'dashboard',
          path: "dashboard",
        },
        {
          id: "2",
          title: "Site",
          url: '/site',
          text: 'site',
          path: "site",
        },
        {
          id: "3",
          title: "Map",
          url: '/map',
          text: 'map',
          path: "map",
        },
        {
          id: "4",
          title: "Alarms",
          url: '/alarms',
          text: 'alarms',
          path: "alarms",
        },
        {
          id: "5",
          title: "CCTV",
          url: '/cctv',
          text: 'cctv',
          path: "cctv",
        },
        {
          id: "6",
          title: "Support Ticket",
          url: '/support-ticket',
          text: 'support-ticket',
          path: "support-ticket",
        },
        {
          id: "7",
          title: "Profile",
          url: '/profile',
          text: 'profile',
          path: "profile",
        },
      ];
    default:
      return [];
  }
};
