import axios, { AxiosResponse, ResponseType } from "axios";
import { getAccesToken, getLocalSlug } from "utils/localstorage";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  timeout: 0,
});

axiosInstance.interceptors.request.use(
  function (config) {
    let authToken = getAccesToken() ?? "";
    let slug = getLocalSlug() ?? "";

    if (authToken) {
      config.headers["Authorization"] = "Bearer " + authToken;
    }
    if (slug) {
      config.headers["companyIdentifier"] = slug;
    }

    // Other headers
    config.headers["accept-language"] = "en";
    config.headers["Accept"] = "application/json";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(function (response) {
    return response;
  },
  function (error) {    
    if (error.response.status === 401) {
      window.location.href = "/";
    }
    if(error.response.status === 403){
      window.location.href = "/access-denied";
    }
    if (error.response.status === 500) {
      window.location.href = "/500";
    }
    if (error.response.status === 404) {
      window.location.href = "/404";
    }
    return error.response;
  }
);

export const request = async ({
  url,
  method = "GET",
  params,
  body,
  headers,
  responseType,
}: {
  url: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  params?: any;
  body?: any;
  headers?: any;
  responseType?: ResponseType;
}) => {
  const res: AxiosResponse = await axiosInstance.request({
    url: url,
    method,
    params,
    data: body,
    headers,
    responseType,
  });

  return res;
};
