import { AxiosResponse } from "axios";
import { request } from "api/index";

export const PushNotificationTokenApi = async (body: any) => {  
    const response: AxiosResponse<any> = await request({
      url: "/device-detail",
      method: "POST",
      body
    });
    return response;
};