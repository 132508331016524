
import React from 'react'
import { TextBoxProps } from 'model/input/input';
import { Controller } from 'react-hook-form';
import { Form} from 'react-bootstrap';
// import SubmitLoader from '../../submitLoader/submitLoader';
// 

const Textbox: React.FC<TextBoxProps> = ({
	extraClass="",
	type = 'text',
	icon,
	control,
	name = '',
	id = '',
	className = '',
	value = '',
	placeholder = '',
	min = 1,
    	max = 30,
	disabled = false,
	readonly = false,
	required = false,
	positive = false,
	autoFocus = false,
	autoComplete = 'on',
	onChange,
	onPaste,
	onKeyDown,
	onBlur,
	label = '',
	errorMessage = '',
	isRequired=false,
	resendCodeStatus='',
	handleResendCodeClick,
	onFocus
}) => {
	const pattern = positive ? /^\d*\.?\d+$/ : undefined;
	return (
		<>
			<Controller
				control={control}
				defaultValue=""
				name={name}
				render={({ field }) => (
					<Form.Group className={`form-group ${extraClass}`}>
						{label && <Form.Label htmlFor={id}>{label}{ isRequired && <span className='required-text fw-bold'></span>}</Form.Label>}
						<Form.Control
							type={type}
							name={name}
							id={id}
							className={`${className} ${errorMessage ? '' : ''}`}
							value={field.value}
							placeholder={placeholder}
							maxLength={max}
							minLength={min}
							disabled={disabled}
							readOnly={readonly}
							required={required}
							pattern={pattern?.source}
							autoFocus={autoFocus}
							autoComplete={autoComplete}
							onChange={(e:any)=>{
								field.onChange(e)
								onChange && onChange(e)
							}}
							onPaste={onPaste}
							onKeyDown={onKeyDown}
							onBlur={onBlur}
							form='novalidatedform'
							onFocus={onFocus}
							accept={type === 'file' ? '.pdf, image/jpeg, image/png, image/jpg' : undefined}
						/>
						{resendCodeStatus ===  '1' && (
                            <span onClick={handleResendCodeClick} className="purple-color cursor-pointer fw-600 mt-1 d-block text-end suggested-password">
                                Resend Code
                            </span>
                        )}
						{resendCodeStatus ===  '2' && (
                            <span  className="mt-1 d-block text-end">
                               {/* <SubmitLoader />  Sending verification code...  */}
								
                            </span>
                        )}
				{errorMessage && (<div className="invalid-feedback d-block">{errorMessage}</div >)}
				</Form.Group>
				)}
			/>
		</>
	);
}

export default Textbox;