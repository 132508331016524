import { request } from "api/index";
import { AxiosResponse } from "axios";

export const GetNotificationApi = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/get-notification",
    method: "POST",
  });
  return response;
};

export const ReadNotificationApi = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/read-notification",
    method: "POST",
  });
  return response;
};
