'use client';

import React from 'react'
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { TextBoxProps } from 'model/input/input';
import appImages from 'components/appImages';



const Password: React.FC<TextBoxProps> = ({
    type = 'password',
    control,
    name = '',
    id = '',
    className = '',
    value = '',
    placeholder = '',
    min = 1,
    max = 30,
    disabled = false,
    readonly = false,
    required = false,
    pattern="",
    autoFocus = false,
    autoComplete = 'off',
    onChange,
    onPaste,
    onKeyDown,
    onBlur,
    onFocus,
    onClick,
    label = '',
    errorMessage = '',
    enableShowPassword = false,
    passwordType,
    handleTogglePassword,
    isSuggestionsRequired,
    handleSuggestionClick,
    isRequired=false,

}) => {
    return (
        <>
            <Controller
                control={control}
                defaultValue={value}
                name={name}
                render={({ field }) => (
                    <Form.Group className="form-group">
                        {label && <Form.Label htmlFor={id}>{label}{ isRequired && <span className='required-text fw-bold'></span>}</Form.Label>}
                        
                            <Form.Control
                                // type={type}
                                type={passwordType}
                                name={name}
                                id={id}
                                className={`${className} ${errorMessage ? '' : ''}`}
                                value={field.value}
                                placeholder={placeholder}
                                maxLength={max}
                                minLength={min}
                                disabled={disabled}
                                readOnly={readonly}
                                required={required}
                                autoFocus={autoFocus}
                                autoComplete={autoComplete}
                                onChange={field.onChange}
                                onPaste={onPaste}
                                onKeyDown={onKeyDown}
                                onBlur={onBlur}
                                onFocus={onFocus}
                            />
                            {enableShowPassword && <span  id="button-addon2" className='hide-show-pass input-img' onClick={handleTogglePassword} >
                                {passwordType === "password" ? (
                                    <img src={appImages.EyeSlash} alt="Hide Password" />
                                ) : (
                                    <img src={appImages.Eye} alt="Show Password" />
                                )}
                            </span>}                                                
                        {isSuggestionsRequired && (
                            <span onClick={handleSuggestionClick} className="purple-color cursor-pointer fw-600 mt-1 d-block text-end suggested-password">
                                Use suggested password.
                            </span>
                        )}
                        {errorMessage && (<div className="invalid-feedback d-block">{errorMessage}</div >)}
                    </Form.Group>
                )}
            />
        </>
    );
}

export default Password;