export const saveLoginDataToLocalStorage = (data: any) => {
  try {
    if (typeof window !== "undefined") {
      localStorage.setItem("_login", JSON.stringify(data));
    }
  } catch (error) {
    console.error(`Error saving data to localStorage: ${error}`);
  }
};

export const getLoginData = () => {
  try {
    const loginData = localStorage.getItem("_login");
    if (loginData) {
      if(JSON.parse(loginData).remember_me === true){
        return JSON.parse(loginData);
      }else {
        return null; 
      }
    }
    return "";
  } catch (error) {
    console.error("Error loading data from localStorage:", error);
    return undefined;
  }
};

export const getAccesToken = () => {
  try {
    const token = localStorage.getItem("AuthSecToken");
    return token;
  } catch (error) {
    console.error(`Error get token from localStorage: ${error}`);
  }
};

export const getLocalSlug = () => {
  try {
    const token = localStorage.getItem("_slug");
    return token;
  } catch (error) {
    console.error(`Error get token from localStorage: ${error}`);
  }
};