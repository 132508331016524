import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

export const SigninSchema = Yup
    .object().shape({
        email: Yup.string().required('email_address_required')
            .matches(/^([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/, 'valid_email_address_required')
            .email('valid_email_address_required')
            .min(5, "minimum_2_characters_required")
            .max(100, "maximum_100_characters_allowed"),

        password: Yup.string()
            .required('password_required')
            .password()
            .minLowercase(1, 'password_must_contain_1_lowercase_letter')
            .minUppercase(1, 'password_must_contain_1_uppercase_letter')
            .minNumbers(1, 'password_must_contain_1_number')
            .minSymbols(1, 'password_must_contain_1_symbol')
            .min(8, 'password_must_8_characters_long')
            .max(64, 'password_cannot_longer_64_characters'),
        slug: Yup.string()
            .required('company_name_required')
          });    

export const AdminSigninSchema = Yup
    .object().shape({
        email: Yup.string().required('email_address_required')
            .matches(/^([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/, 'valid_email_address_required')
            .email('valid_email_address_required')
            .min(5, "minimum_2_characters_required")
            .max(100, "maximum_100_characters_allowed"),

        password: Yup.string()
            .required('password_required')
            .password()
            .minLowercase(1, 'password_must_contain_1_lowercase_letter')
            .minUppercase(1, 'password_must_contain_1_uppercase_letter')
            .minNumbers(1, 'password_must_contain_1_number')
            .minSymbols(1, 'password_must_contain_1_symbol')
            .min(8, 'password_must_8_characters_long')
            .max(64, 'password_cannot_longer_64_characters')
          });        

export const ForgotPasswordValidaion = Yup.object().shape({
  email: Yup.string().required("email_address_required")
    .matches(/^([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/, 'valid_email_address_required')  
    .email("valid_email_address_required")
    .min(5, "minimum_2_characters_required")
    .max(100, "maximum_100_characters_allowed"),
    slug: Yup.string()
          .required('company_name_required')
});
export const AdminForgotPasswordValidaion = Yup.object().shape({
  email: Yup.string().required("email_address_required")
    .matches(/^([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,6}$/, 'valid_email_address_required')  
    .email("valid_email_address_required")
    .min(5, "minimum_2_characters_required")
    .max(100, "maximum_100_characters_allowed")
});

export const ResetPasswordValidation = Yup.object().shape({
  new_password: Yup.string()
    .required("password_required")
    .password()
    .minLowercase(1, "password_must_contain_1_lowercase_letter")
    .minUppercase(1, "password_must_contain_1_uppercase_letter")
    .minNumbers(1, "password_must_contain_1_number")
    .minSymbols(1, "password_must_contain_1_symbol")
    .min(8, "password_must_8_characters_long")
    .max(64, "password_cannot_longer_64_characters"),
  confirm_password: Yup.string()
    .required("please_confirm_password")
    .test("passwords-match", "passwords_must_match", function (value) {
      return this.parent.new_password === value;
    }),
});

export const ChangePasswordValidation = Yup.object().shape({
  current_password: Yup.string()
    .required("password_required")
    .password()
    .minLowercase(1, "password_must_contain_1_lowercase_letter")
    .minUppercase(1, "password_must_contain_1_uppercase_letter")
    .minNumbers(1, "password_must_contain_1_number")
    .minSymbols(1, "password_must_contain_1_symbol")
    .min(8, "password_must_8_characters_long")
    .max(64, "password_cannot_longer_64_characters"),
  new_password: Yup.string()
    .required("new_password_required")
    .password()
    .minLowercase(1, "password_must_contain_1_lowercase_letter")
    .minUppercase(1, "password_must_contain_1_uppercase_letter")
    .minNumbers(1, "password_must_contain_1_number")
    .minSymbols(1, "password_must_contain_1_symbol")
    .min(8, "password_must_8_characters_long")
    .max(64, "password_cannot_longer_64_characters")
    .test(
      "passwords-match",
      "passwords_not_same_as_current_password",
      function (value) {
        return this.parent.current_password !== value;
      }
    ),
  confirm_password: Yup.string()
    .required("please_confirm_password")
    .test("passwords-match", "confirm_password_should_match_new_password", function (value) {
      return this.parent.new_password === value;
    }),
});
