import React from "react";
import { Navigate } from "react-router-dom";
// import { UseUserState } from 'context/globalContext';

interface componentType{
  component: React.FunctionComponent, 
  permission:  string
  setting?: boolean
}

export const AdminPrivateRoute = ({component,permission, setting=false}:componentType) => {

    const permissions = ['view_admin_dashboard', 'view_admin_profile', 'view_admin_customer', 'view_admin_customer', 'view_admin_map'];
    const reactComponent = React.createElement(component);


    return(<>
        { permissions &&  permissions.includes(permission)
            ? reactComponent
            : <Navigate to={{pathname: '/404'}}/>
        }

    </>)
};