
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage} from 'firebase/messaging';


// var firebaseConfig = {
//     apiKey: "AIzaSyBNh7nHBh1xk_akpuldIdfCtdrPGCkLeCs",
//     authDomain: "wstym-dev.firebaseapp.com",
//     projectId: "wstym-dev",
//     storageBucket: "wstym-dev.appspot.com",
//     messagingSenderId: "48828747964",
//     appId: "1:48828747964:web:7e9aafa4face14adf640b9",
//     measurementId: "G-6WC49L1VP2"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDnc_VS2KCzNJY01cwgWb76xKr40-LWjkI",
  authDomain: "wstym-a5dea.firebaseapp.com",
  projectId: "wstym-a5dea",
  storageBucket: "wstym-a5dea.appspot.com",
  messagingSenderId: "1007446563339",
  appId: "1:1007446563339:web:d8a0a819730aff5a6ec50e"
};
initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = (postNotifyToken: (item: any) => void) => {

  
return getToken(messaging, { vapidKey: "BCYWeK3Whm-dmZ6BV4EgBE3STKW8Tur5WqZ8en7D82afRjGKlhFs1dl4lscBn_EfAWpLx1tD_sNPXumTiqHag7A"})
    .then((currentToken:any) => {
    if (currentToken) {
      localStorage.setItem('d_token', currentToken);
      postNotifyToken({ device_token: currentToken });
      return currentToken;
        // console.log('current token for client: ', currentToken);
        // Perform any other neccessary action with the token
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
    }
    })
    .catch((err:any) => {
    console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload:any) => {
      // console.log("payload", payload)
      resolve(payload);
    });
  });
