import { useEffect, useState, useRef } from "react";
import {
  APIProvider,
  Map,
  useMap,
  AdvancedMarker,
  InfoWindow,
  Pin,
} from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import type { Marker } from "@googlemaps/markerclusterer";
import "components/floorManager/floorManager.scss";
import i18n from "config/i18next";
import { Link } from "react-router-dom";
import { UseUserState } from "context/userContext";
import { CompanyOwnerRole } from "data/constant";

export default function GoogleMapComp({mapData = [], defaultZoom, height = "60vh", width = '100%'}:any) {
  const [currLang, setCurrLang] = useState<string>(i18n.language);

  const MAP_CONFIGS: MapConfig[] = [
    {
      id: 'styled1',
      label: 'Raster / "Bright Colors" (no mapId)',
      mapId: "fa3dd4cbe01e3f92",
      // mapId: "a9c4b3e6878e8d8f",
    },
    
  ];
  const [mapConfig, setMapConfig] = useState<MapConfig>(MAP_CONFIGS[0]);

  useEffect(() => {
    setCurrLang(i18n.language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  type Tree = {
    sites_name: string;
    company_details: any;
    latitude: number;
    address: string;
    alarm_count: string;
    longitude: number;
    status: any;
    site_alarm_status: any;
    id: any;
  };

  // let formatted:any[] = [];
  let centerMap = {lat: mapData.length>0 ? +mapData[0]?.latitude : 24.711709, lng: mapData.length>0 ? +mapData[0].longitude : 46.673849}

  const formatted = (mapData.length > 0) ? mapData?.map((item:Tree) => ({
    name: item?.sites_name ?? '-',
    lat: +item?.latitude ?? 0,
    lng: +item?.longitude ?? 0,
    siteId: item?.id,
    address: item?.address?.slice(0, 20) ?? '-',
    alarm_count: item?.alarm_count ?? "-",
    status: item?.status ?? null,
    alarm_status: item?.site_alarm_status ?? null,
    company_name: item.company_details?.company_name ?? '-',
    key: JSON.stringify({ name: item?.sites_name ?? '', lat: item?.latitude ?? 0, lng: item?.longitude ?? 0 })
  })) : [];
  
  type MapConfig = {
    id: string;
    label: string;
    mapId?: string;
    mapTypeId?: string;
    styles?: google.maps.MapTypeStyle[];
  };

  return (
    <div style={{ height: height, width: width }}>
      <APIProvider language={currLang} apiKey={"AIzaSyCTo0jtV7jQilDuYuzZu2gvHyTQc1YsR7M"}>
        <Map
          center={{ lat: centerMap?.lat, lng: centerMap?.lng}}
          zoom={defaultZoom}
          // mapId={"wstym-map"}
          mapTypeControl={false}
          streetViewControl={false}
          mapId={mapConfig.mapId}
        >
          <Markers points={formatted} />
        </Map>
      </APIProvider>
    </div>
  );
}

type Point = google.maps.LatLngLiteral & { key: string } & {data : any} & { status: any } & { alarm_status: any };
type Props = { points: Point[] };

const Markers = ({ points }: Props) => {
  const [openMarkerInfo, setOpenMarkerInfo] = useState<any>(null);
  const map = useMap();
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userData } = UseUserState();

  useEffect(() => {
    setIsLoading(true);
    clusterer.current?.clearMarkers();

    // Simulate API call delay for loading purposes
    const timeout = setTimeout(() => {
      setMarkers({});
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  // useEffect(() => {
  //   if (!map) return;
  //   setOpenMarkerInfo(null)
  //   if (!clusterer.current) {
  //     clusterer.current = new MarkerClusterer({ map });
  //   }

  //   if(points && points.length > 1) {
  //   var bounds = new google.maps.LatLngBounds();

  //   points.map((point) => bounds.extend(point));
  //   map.fitBounds(bounds);
  // }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [map, points]);

  useEffect(() => {
    if (!map) return;
    
    // Store current zoom level and bounds
    const currentZoom:any = map.getZoom();
    const currentBounds:any = map.getBounds();
  
    setOpenMarkerInfo(null);
  
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  
    if (points && points.length > 1) {
      var bounds = new google.maps.LatLngBounds();
      points.forEach((point) => bounds.extend(point));
      
      map.fitBounds(currentBounds ?? bounds);
      map.setZoom(currentZoom);
  
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, points]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };
  
  return (
    <>
       {isLoading && <div>Loading...</div>}
      {!isLoading &&
        points && points.map((point, index) => (
        <>
        <AdvancedMarker
          position={point}
          key={point.key}
          ref={(marker:any) => setMarkerRef(marker, point.key)}
          onClick={() => setOpenMarkerInfo({lat:point.lat, lng:point.lng, data:point})}
        >
          <Pin background={point?.status === "0" ? "#525252" : point?.alarm_status === 1 ? "#D10000" : "#006400" } glyphColor={'#FFF'} borderColor={'#FFF'} />
        </AdvancedMarker>
        </>
      ))}
      {openMarkerInfo && (
        <InfoWindow key={openMarkerInfo.lat} position={{lat:openMarkerInfo.lat, lng:openMarkerInfo.lng}} >
          <div className="floor-detail-card" key={openMarkerInfo.lat}>
          <ul className="mb-0 ps-0" key={openMarkerInfo.lat}>
            <li key={openMarkerInfo.data?.name}><p><span>Station Name: {" "}</span>{userData?.role_id === CompanyOwnerRole ? <Link style={{textDecoration: 'none'}} to={`/${userData.slug}/site/detail/${openMarkerInfo.data.siteId}`}> {openMarkerInfo.data?.name ?? '-'}</Link> : openMarkerInfo.data?.name ?? '-'}</p></li>
            <li key={openMarkerInfo.data?.company_name}><p><span>Company Name: {" "}</span> {openMarkerInfo.data?.company_name ?? '-'}</p></li>
            <li key={openMarkerInfo.lng}><p><span>Longitude: {" "}</span> {openMarkerInfo.lng ?? "-"}</p></li>
            <li key={openMarkerInfo.lat}><p><span>Latitude: {" "}</span> {openMarkerInfo.lat ?? '-'}</p></li>
            <li key={openMarkerInfo.data?.address}><p><span>Address: {" "}</span> {openMarkerInfo?.data?.address ?? '-'}</p></li>
              <li key={openMarkerInfo?.data?.alarm_count}><p><span>Alarms: {" "}</span> {openMarkerInfo?.data?.alarm_count ?? '-'}</p></li>
              {/* <li><p><span>Power Consumption: {" "}</span> {floorManagerDetail?.floormanager_info?.supervisor_details?.user_details?.name ?? '-'}</p></li> */}
          </ul>
        </div>
        </InfoWindow>
      )}
    </>
  );
};