import { useEffect, useState } from "react";
import appImages from "components/appImages";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { Switch } from "components/common/form/input/switch";
import Textbox from "components/common/form/input/textBox";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import "components/customerDetail/customerDetail.scss";
import { useMutation } from "@tanstack/react-query";
import SubmitLoader from "components/common/submitLoader/submitLoader";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import SectionLoader from "components/common/sectionLoader/sectionLoader";
import { getSiteDetailApi, updateSiteDetailApi } from "api/site";
import { AddEditSiteSchema } from "utils/schemaValidations/site";
import Select from "react-select";
import { AreaListApi } from "api/areaApis";
import CreateAlarm from "components/alarms/create";
import "components/site/site.scss";
import GoogleMapComp from "components/common/map/googleMap";
import { UseUserState } from "context/userContext";
import { useTranslation } from "react-i18next";
const EditSite = () => {
  const { t } = useTranslation(["common", "alarm", "validation"]);
  const [siteDetail, setSiteDetail] = useState<any>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<any>([]);
  const [selectedArea, setSelectedArea] = useState<any>([]);
  const [areaList, setAreaList] = useState<any>([]);
  const [showAlarmModal, setShowAlarmModal] = useState<boolean>(false);
  const {userData} = UseUserState();

  const { siteKey } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (siteKey) {
      getSiteDetails({ id: siteKey });
      getAreaList();
    } else {
      navigate(`/${userData.slug}/site`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    mutate: getAreaList,
    // isLoading: areaLoding,
    // error: areaErrorLog,
  } = useMutation(AreaListApi, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        setAreaList(data.data.result);
      }
    },
    onError: (errorData: any) => {
      console.log(errorData);
    },
  });

  const {
    mutate: getSiteDetails,
    isLoading,
    // error,
  } = useMutation(getSiteDetailApi, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        setSiteDetail(data.data.result);
        const editData = {
          site_id: data.data.result.id,
          site_name: data.data.result.sites_name ?? "",
          address: data.data.result.address ?? "",
          longitude: data.data.result.longitude ?? "",
          latitude: data.data.result.latitude ?? "",
          area_id: data.data.result.area_id ?? "",
          status: Number(data.data.result.status),
        };
        setValue("status", Number(data.data.result.status));
        setSelectedArea([data.data.result.area]);
        setIsChecked(data.data.result.status === "1" ? true : false);
        setDefaultValues(editData);
      }
    },
    onError: (errorData: any) => {
      console.log(errorData);
    },
  });

  //Form validation
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddEditSiteSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, reset]);

  const {
    mutate: updateSite,
    isLoading: isUpdating,
    // error: updateError,
  } = useMutation(updateSiteDetailApi, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        toast.success(data.data.message);
        navigate(`/${userData.slug}/site`);
      }
      if (data?.status === 422) {
        toast.error(data.data.message);
      }
    },
    onError: (errorData: any) => {
      console.log(errorData);
    },
  });

  //update customer
  const onSubmit = (submitFormdata: any) => {
    updateSite(submitFormdata);
  };

  useEffect(() => {
    setValue("status", isChecked ? 1 : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  const closeModal = (status: boolean) => {
    setShowAlarmModal(false);
    if (status) {
      siteDetail.alarm_count = Number(siteDetail.alarm_count) + 1;
    }
  };

  const openModal = () => setShowAlarmModal(true);

  if (isLoading) {
    return <SectionLoader />;
  }

  return (
    <>
      <div className="main-heading d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <Link to={`/${userData.slug}/site`} className="btn btn-back">
            <Image src={appImages.ArrowBack} className="btn-img-left" alt="" />
            {t("common:btn_back")}
          </Link>
          <h2>{getValues("site_name")}</h2>
        </div>
      </div>
      <div className="card edit-site-card">
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row>
            <Col xl={6} lg={12}>
              <div className="dashboard-map">
                {siteDetail && (
                  <GoogleMapComp
                    height={"70vh"}
                    mapData={[siteDetail]}
                    defaultZoom={12}
                  />
                )}
              </div>
            </Col>
            <Col xl={6} lg={12}>
              <div className="form-group">
                <Form.Label htmlFor="site_name">{t("common:site_name")}</Form.Label>
                <Textbox
                  control={control}
                  type="text"
                  id="site_name"
                  name="site_name"
                  value=""
                  className="form control"
                  placeholder={t("site:enter_site_name")}
                  autoComplete="off"
                  max={100}
                  errorMessage={errors.site_name && t(`validation:${errors.site_name?.message}`)}
                />
              </div>
              <div className="form-group">
                <Form.Label htmlFor="address">{t("common:address")}</Form.Label>
                <Textbox
                  control={control}
                  type="text"
                  id="address"
                  name="address"
                  value=""
                  className="form control"
                  placeholder={t("site:enter_address")}
                  autoComplete="off"
                  max={250}
                  errorMessage={errors.address && t(`validation:${errors.address?.message}`)}
                />
              </div>
              <div className="form-group">
                <label>{t('common:area')}</label>
                <div className="react-select-wrapper me-0">
                  <Form className="react-select-input">
                    <Select
                      getOptionLabel={(item: any) => item.name}
                      getOptionValue={(item: any) => item.id}
                      id="area"
                      name="area"
                      options={areaList ?? []}
                      isSearchable
                      onChange={(option) => {
                        setValue("area_id", option.id ? option.id : "", {
                          shouldDirty: true,
                        });
                        setSelectedArea(option);
                        clearErrors("area_id");
                      }}
                      value={selectedArea}
                      placeholder={t('common:select_area')}
                      classNamePrefix="react-select"
                      className="react-select-container"
                    />
                  </Form>
                </div>
              </div>
              <div className="form-group">
                <Form.Label htmlFor="longitude">{t("site:longitude")}</Form.Label>
                <Textbox
                  control={control}
                  type="text"
                  id="longitude"
                  name="longitude"
                  value=""
                  className="form control"
                  placeholder={t("site:enter_longitude")}
                  max={20}
                  autoComplete="off"
                  errorMessage={errors.longitude && t(`validation:${errors.longitude?.message}`)}
                />
              </div>
              <div className="form-group">
                <Form.Label htmlFor="latitude">{t("site:latitude")}</Form.Label>
                <Textbox
                  control={control}
                  type="text"
                  id="latitude"
                  name="latitude"
                  value=""
                  className="form control"
                  placeholder={t("site:enter_latitude")}
                  autoComplete="off"
                  errorMessage={errors.latitude && t(`validation:${errors.latitude?.message}`)}
                  isRequired={true}
                  max={20}
                />
              </div>
              <div className="form-group">
                <label htmlFor=""> {t("common:alarms")}</label>
                <div className="d-flex align-items-center justify-content-between add-alarm">
                  <span>{siteDetail?.alarm_count ?? 0} {t("common:alarms")}</span>
                  <Button
                    type="button"
                    className="btn-white btn-sm"
                    variant=""
                    onClick={() => {
                      setShowAlarmModal(true);
                    }}
                  >
                  {t("common:btn_add_new")}
                  </Button>
                </div>
              </div>
              <div className="form-group">
                <ul className="ps-0 mb-0 site-detail">
                  <li className="d-flex">
                    <p className="mb-0">{t("site:power_consumption")}:</p>
                    <span>{``}</span>
                  </li>
                  <li className="d-flex">
                    <p className="mb-0">{t("common:cctv")}:</p>
                    <span>{siteDetail?.cctv_count ?? 0}</span>
                  </li>
                  <li className="d-flex">
                    <p className="mb-0">{t("common:supervisor")}:</p>
                    <span>{siteDetail?.supervisor_count ?? 0}</span>
                  </li>
                  <li className="d-flex">
                    <p className="mb-0">{t("common:site_manager")}:</p>
                    <span>{siteDetail?.floormanager_count ?? 0}</span>
                  </li>
                </ul>
              </div>
              <div className="form-group mb-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center header-switch">
                    <span>{t("common:status")}:</span>
                    <Switch
                      handleToggleSwitch={() => {
                        setIsChecked(!isChecked);
                      }}
                      checked={isChecked}
                      loader={false}
                    />
                  </div>
                  <div className="d-flex gap-3">
                    <Button
                      type="submit"
                      className="btn btn-outline-primary btn-lg"
                      onClick={() => {
                        navigate(`/${userData.slug}/site`);
                      }}
                    >
                      {t("common:btn_cancel")}
                    </Button>
                    <Button
                      type="submit"
                      className="btn-lg"
                      variant="primary"
                      disabled={isUpdating}
                    >
                      {isUpdating ? <SubmitLoader /> : t("common:btn_save")}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        {showAlarmModal && (
          <CreateAlarm
            title={t("alarm:add_new_alarm")}
            show={showAlarmModal}
            handleClose={closeModal}
            handleShow={openModal}
            type={"site"}
            data={{ site_id: getValues("site_id") }}
          />
        )}
      </div>
    </>
  );
};

export default EditSite;
