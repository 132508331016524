import { AxiosResponse } from "axios";
import { request } from "api/index";

export const GetAreaListApi = async (body: any) => {  
    const response: AxiosResponse<any> = await request({
      url: "/company/area-list",
      method: "POST",
      body
    });
    return response;
};

export const CreateAreaApi = async (body: any) => {  
  const response: AxiosResponse<any> = await request({
    url: "/company/add-area",
    method: "POST",
    body
  });
  return response;
};

export const UpdateAreaApi = async (body: any) => {  
  const response: AxiosResponse<any> = await request({
    url: "/company/update-area",
    method: "POST",
    body
  });
  return response;
};

export const DeleteAreaApi = async (body: any) => {  
  const response: AxiosResponse<any> = await request({
    url: "/company/delete-area",
    method: "POST",
    body
  });
  return response;
};

export const AreaListApi = async () => {  
  const response: AxiosResponse<any> = await request({
    url: `/area-name-list`,
    method: "GET",
  });
  return response;
};

export const AreaListyCompanyApi = async (id: any) => {  
  const response: AxiosResponse<any> = await request({
    url: `/area-name-list/${id}`,
    method: "GET",
  });
  return response;
};

export const areaWiseSitesApi = async (body: any) => {  
  const response: AxiosResponse<any> = await request({
    url: "/company/multiple-area-site-list",
    method: "POST",
    body
  });
  return response;
};
