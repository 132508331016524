import React from 'react';
import { ModalProps } from 'model/modal/modal';
import { Modal } from 'react-bootstrap';
import appImages from 'components/appImages';
import 'components/common/commonModal/commonModal.scss';

const CommonModal: React.FC<ModalProps> = ({ title, show, handleClose, children, dynamicCLass }) => {
    return (
        <Modal show={show} onHide={()=>handleClose(false)} centered className={`${dynamicCLass}`} backdrop="static">
            <Modal.Header>
                <h4 className='mb-0'>{title}</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>handleClose(false)}>
                    <img src={appImages.BtnClose} alt="Hide Password" />
                </button>
            </Modal.Header>
            <Modal.Body >
                {children}
            </Modal.Body>
        </Modal>
    );
}
export default CommonModal;