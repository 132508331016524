import React from 'react'
// import { Link } from "react-router-dom";
import "components/common/footer/footer.scss";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation(["common"]);
    return (
        <footer className='d-xl-flex d-block align-items-center justify-content-lg-between justify-content-center'>
            <div className='footer-copright'>
                {t("common:copyright_msg")}
            </div>
            <div className='footer-links'>
                {/* <ul className='ps-0 d-flex align-items-center mb-0'>
                    <li>
                        <Link to={'/'}>Contact Us</Link>
                    </li>
                    <li>
                        <Link to={'/'}>About Us</Link>
                    </li>
                    <li>
                        <Link to={'/'}>Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to={'/'}>Terms and Conditions</Link>
                    </li>
                    <li>
                        <Link to={'/'}>Need Help?</Link>
                    </li>
                </ul> */}
            </div>
        </footer>
    )
}

export default Footer
