import { useQuery } from "@tanstack/react-query";
import { GetProfileAPI } from "api/profileApis";
import i18n from "config/i18next";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getAccesToken, getLocalSlug } from "utils/localstorage";

interface UserGlobalModel {
  id: number;
  name: string;
  email: string;
  mobile: string;
  role_id: number;
  is_active: number;
  company_name: string;
  slug: string;
  token: string;
  profile_image_url: string;
  unread_notification: number|string;
  language: string;
}

const UserIntialValue: UserGlobalModel = {
  id: 0,
  name: "",
  email: "",
  mobile: "",
  role_id: 0,
  is_active: 1,
  company_name: "",
  slug: "",
  token: "",
  profile_image_url: "",
  unread_notification: 0,
  language: ''
};

const userInitialState = {
  userData: UserIntialValue,
  setUserData: (UserGlobalModel: any) => {},
};

export const UserStateContext = createContext(userInitialState);
export function GlobalContextProvider({ children }: any) {

  const [userData, setUserData] = useState(userInitialState.userData);
  const slug = getLocalSlug();
  const location = useLocation();
  const authPaths = ['/access-denied', '/signin', '/', "/admin/signin", "/admin/forgot-password", "/forgot-password", `/${slug}`, "/404", "/500", "/access-denied"];
  const excludedPaths = ["create-password", "reset-password", "reset-password-admin", "forgot-password"];
  
  const {
    refetch: profileDataRefetch,
    // data,
    // isPreviousData
  } = useQuery(["ProfileDataAPI"], () => GetProfileAPI(), {
    enabled: false,
    keepPreviousData: true,
    onSuccess(data) {
      if (data?.status === 200) {
        setUserData(data?.data?.result);
      }
      if(i18n.language != data?.data?.result?.language){
        i18n.changeLanguage(data?.data?.result?.language)
      }
    },
    onError: (errorData: any) => {
      toast.error(errorData);
    },
  });

  useEffect(() => {
    if (getAccesToken() !== null && !authPaths.includes(location.pathname) && !location.pathname.split("/").some(part => excludedPaths.includes(part))) {
      profileDataRefetch();
    } else {
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userContextValue = useMemo(() => {
    return { userData, setUserData };
  }, [userData]);

  return (
    <UserStateContext.Provider value={userContextValue}>
      {children}
    </UserStateContext.Provider>
  );
}

export const UseUserState = () => useContext(UserStateContext);
