
import { Navigate, useRoutes } from 'react-router-dom';
import PublicRouteList from 'routes/publicRouteList';
import AuthLayout from 'layout/authLayout/authLayout';
import AdminRoutes from './admin/adminRoute';
import UserRoutes from './userRoutes';
import { getAccesToken, getLocalSlug } from 'utils/localstorage';
import PageNotFound from 'components/pages/404';
import PrivacyPolicy from 'components/privacyPolicy/privacyPolicy';

export default function AppRoutes() {

    const slug = getLocalSlug();

    // const adminAuthRoutes = ['/admin/signin', '/admin/forgot-password', '/reset-password-admin/:email/:token'];
    // const authRoutes = [`/${slug}/forgot-password`, `/${slug}`];

    // useEffect(() => {
    //     if((getAccesToken() != null || getAccesToken() != undefined) && adminAuthRoutes?.includes(location.pathname)){
    //       navigate('/admin/dashboard');
    //     }
    //     else if((getAccesToken() != null || getAccesToken() != undefined) && authRoutes?.includes(location.pathname)){
    //         navigate(`/${slug}/dashboard`);
    //       }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [location.pathname]);
      
    return useRoutes(

        [PublicRouteList,
            {
                path: '/',
                element: getAccesToken() == null ? <Navigate to='/admin/signin' /> : <AuthLayout />,
                children: AdminRoutes
            },
            {
                path: '/',
                element: getAccesToken() == null ? <Navigate to={slug ? `/${slug}` : '/'} /> : <AuthLayout />,
                children: UserRoutes
            },
            {
                path: '/*',
                element: <PageNotFound />,    
            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy />,    
            },
        ]
    );
}
