
import { lazy } from "react";
import { AdminPrivateRoute } from "./adminPrivateRoute";
const AdminDashboard = lazy(() => import("components/dashboard/adminDashboard/adminDashboard"));
const Customer = lazy(() => import("components/customer/customer"));
const CustomerDetail = lazy(() => import("components/customerDetail/customerDetail"));
const Map = lazy(() => import("components/map/map"));
const ProfileDetail = lazy(
  () => import("components/profileDetail/profileDetail")
);

const AdminRoutes = [
  {
    path: "/admin/dashboard",
    element: (<AdminPrivateRoute component={AdminDashboard} permission="view_admin_dashboard" />),
  },
  {
    path: "/admin/profile",
    element: (
      <AdminPrivateRoute component={ProfileDetail} permission="view_admin_profile" />
    ),
  },
  {
    path: "/admin/customer",
    element: (
      <AdminPrivateRoute component={Customer} permission="view_admin_customer" />
    ),
  },
  {
    path: "/admin/customer/detail/:customerKey",
    element: (
      <AdminPrivateRoute component={CustomerDetail} permission="view_admin_customer" />
    ),
  }, 
  {
    path: "/admin/map",
    element: (
      <AdminPrivateRoute component={Map} permission="view_admin_map" />
    ),
  },
];

export default AdminRoutes;
