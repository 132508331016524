import * as Yup from 'yup';
import YupPassword from 'yup-password'
YupPassword(Yup);

export const AddEditAlarmSchema = Yup.object().shape({
    alarm_type_id: Yup.string().required("alarm_type_required"),
    name: Yup.string().required("name_required"),        
    link: Yup.string().required("link_required"),
    location: Yup.string().required("location_required"),        
    site_id: Yup.string().required("site_required"),
});