"use client";

import React, { ChangeEvent } from "react";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { CheckBoxProps } from "model/input/input";

const Checkbox: React.FC<CheckBoxProps> = ({
  type = "checkbox",
  control,
  name = "",
  id = "",
  className = "",
  checked,
  onChange,
  onClick,
  label = "",
  errorMessage = "",
  isRequired = false,
  topLabel = "",
  formGroupclassName="",
}) => {
  return (
    <>
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({ field }) => (
          <Form.Group className={`form-group ${formGroupclassName}`}>
            {topLabel && (
              <Form.Label htmlFor={id} className="mb-0">
                {topLabel}
              </Form.Label>
            )}
            {isRequired && <span className="required-text fw-bold"></span>}
            <div
              key={`inline-${type}`}
              className={`form-checkbox ${className}`}
            >
              <Form.Check
                type={type}
                id={id}
                label={label}
                onClick={onClick}
                {...field}
                checked={checked}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e);
                  onChange && onChange(e);
                }}
              />
              {errorMessage && (
                <div className="invalid-feedback d-block">{errorMessage}</div>
              )}
            </div>
          </Form.Group>
        )}
      />
    </>
  );
};

export default Checkbox;
