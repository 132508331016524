import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { ResetPasswordValidation } from "utils/schemaValidations/signinSchema";
import { ResetPasswordSchema, ResetPasswordSubmitSchema } from "model/auth";
import { useNavigate, useParams } from "react-router-dom";
import Password from "components/common/form/input/password";
import { useMutation } from "@tanstack/react-query";
import { ResetPasswordAPI } from "api/authApis";
import SubmitLoader from "components/common/submitLoader/submitLoader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const AdminResetPassword = () => {
  const { t } = useTranslation(["common", "auth", "validation"]);
  const navigate = useNavigate();
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const { email, token } = useParams();

  const handleToggleNewPassword = () => {
    setNewPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };
  const handleToggleConfirmPassword = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm<ResetPasswordSchema>({
      resolver: yupResolver(ResetPasswordValidation),
    });

  const {
    mutate,
    isLoading: isSubmitLoading,
    // error: mutateError,
  } = useMutation(ResetPasswordAPI, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        navigate("/admin/signin");
        toast.success(data.data.message)
      }
      if (data?.status === 422) {
        toast.error(data.data.message)
    }
    },
    onError: (errorData: any) => {
        toast.error(errorData);
    },
  });

  const onSubmit = async (data: ResetPasswordSchema) => {
    const payload: ResetPasswordSubmitSchema = {
      ...data,
      email: email,
      token: token,
    };
    await mutate(payload);
  };

  return (
    <>
      <div className="signin-form d-flex align-items-center justify-content-center h-100">
        <div>
          <div className="text-center">
            <h1>{t("auth:reset_password")}</h1>
            <p>{t("auth:reset_password_msg")}</p>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Password
              control={control}
              type="password"
              id="new_password"
              name="new_password"
              value=""
              className="form control"
              placeholder={t("common:new_password")}
              label={t("common:new_password")}
              errorMessage={errors.new_password && t(`validation:${errors.new_password?.message}`)}
              enableShowPassword={true}
              passwordType={newPasswordType}
              handleTogglePassword={handleToggleNewPassword}
              isRequired={false}
            />
            <Password
              control={control}
              type="password"
              id="confirm_password"
              name="confirm_password"
              value=""
              className="form control"
              placeholder={t("auth:re_enter_new_password")}
              label={t("common:confirm_new_password")}
              errorMessage={
                errors.confirm_password && t(`validation:${errors.confirm_password?.message}`)
              }
              enableShowPassword={true}
              isRequired={false}
              passwordType={confirmPasswordType}
              handleTogglePassword={handleToggleConfirmPassword}
            />
            <Button
              type="submit"
              variant="primary"
              disabled={isSubmitLoading}
              className="w-100"
            >
              {isSubmitLoading && <SubmitLoader />}
              {t("auth:reset_password")}
            </Button>
          </Form>
        </div>
      </div>
      {/* <Footer></Footer> */}
    </>
  );
};

export default AdminResetPassword;
