
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "routes/index";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalContextProvider } from "context/userContext";
import { PushNotification } from "components/common/pushNotification/pushNotification";

const queryClient = new QueryClient();
function App() {
 

  return (
    <>
      <Router basename="/">
          <QueryClientProvider client={queryClient}>
            <GlobalContextProvider>
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                // pauseOnHover
                // theme="colored"
                transition={Slide}
                style={{ zIndex: "999999" }}
              />
              <PushNotification />
              <AppRoutes />
            </GlobalContextProvider>
          </QueryClientProvider>
      </Router>
    </>
  );
}

export default App;
