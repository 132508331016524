import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { getLocalSlug } from "utils/localstorage";

interface componentType{
  component: React.FunctionComponent, 
  permission:  string
  setting?: boolean
}

export const PrivateRoute = ({component,permission, setting=false}:componentType) => {
    // const permissions = [ 'view_dashboard', 'view_sites','view_profile','view_alarm', 'view_customer', 'view_map', 'view_cctv', 'view_area', 'view_supervisor', 'view_floormanager', 'view_support_ticket'];
    const permissions = ['view_dashboard', 'view_sites','view_profile','view_alarm', 'view_customer', 'view_map', 'view_cctv', 'view_area', 'view_supervisor', 'view_floormanager', 'view_support_ticket', 'view_settings'];
    const { slug } = useParams()
    
    if(slug !== getLocalSlug()){
        return(<><Navigate to={{pathname: '/404'}}/></>)
    }

    const reactComponent = React.createElement(component);
    if(permissions?.length === 0){
        return(<><Navigate to={{pathname: '/404'}}/></>)
    }

    return(<>
        { permissions &&  permissions.includes(permission)
            ? reactComponent
            : <Navigate to={{pathname: '/404'}}/>
        }

    </>)
};