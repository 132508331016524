import { request } from "api/index";
import { AxiosResponse } from "axios";

export const GetProfileAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/profile",
    method: "GET",
  });
  return response;
};

export const UpdateProfileAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-profile",
    method: "POST",
    body,
  });
  return response;
};

export const ChangePasswordAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/change-password",
    method: "POST",
    body,
  });
  return response;
};

export const GetCompanyLogoAPI = async () => {
  const response: AxiosResponse<any> = await request({
    url: "/get-company-logo",
    method: "GET",
  });
  return response;
};

export const UpdateCompanyLogoAPI = async (body: any) => {
  const response: AxiosResponse<any> = await request({
    url: "/update-company-logo",
    method: "POST",
    body,
  });
  return response;
};