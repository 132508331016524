import React, { useState, useEffect } from "react";
import { requestForToken, onMessageListener } from "./firebase";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@tanstack/react-query";
import { PushNotificationTokenApi } from "api/pushNotification";
import { UseUserState } from "context/userContext";
import { GetProfileAPI } from "api/profileApis";
import { getAccesToken, getLocalSlug } from "utils/localstorage";
import { useLocation } from "react-router-dom";
import i18n from "config/i18next";

export const PushNotification = () => {
  const [notification, setNotification] = useState({ title: "", body: "", data: "" });
  const { setUserData, userData } = UseUserState();
  const slug = getLocalSlug();
  const location = useLocation();
  const authPaths = ['/access-denied', '/signin', '/', "/admin/signin", "/admin/forgot-password", "/forgot-password", `/${slug}`, "/404", "/500", "/access-denied"];
  const excludedPaths = ["create-password", "reset-password", "reset-password-admin", "forgot-password"];

  // navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function(reg) {
  //   if(reg.installing) {
  //          console.log('Service worker installing');
  //      } else if(reg.waiting) {
  //          console.log('Service worker installed');
  //      } else if(reg.active) {
  //          console.log('Service worker active');
  //      }
  //   // Include below mentioned validations
  //  })

  const {
    refetch: profileDataRefetch,
    // isFetching,
    // data,
  } = useQuery(["ProfileDataAPI"], () => GetProfileAPI(), {
    enabled: false,
    keepPreviousData: true,
    onSuccess(data) {
      if (data?.status === 200) {
        setUserData(data?.data?.result);
      }
      if(i18n.language != data?.data?.result?.language){
        i18n.changeLanguage(data?.data?.result?.language)
      }
    },
    onError: (errorData: any) => {
      toast.error(errorData);
    },
  });

  useEffect(() => {
    if (getAccesToken() !== null && !authPaths.includes(location.pathname) && !location.pathname.split("/").some(part => excludedPaths.includes(part))) {
      profileDataRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  useEffect(() => {
    if (notification?.title) {
      toast.info(<div><b>{notification?.title}</b>: {notification?.body}</div>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const {
    mutate: postNotifyToken,
    // isLoading,
    // error: ErrorLog,
  } = useMutation(PushNotificationTokenApi, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
      }
    },
    onError: (errorData: any) => {
      console.log(errorData);
    },
  });

  useEffect(() => {
    const fetchData = async () => {
        try {
          if(userData?.role_id){
            await requestForToken(postNotifyToken);
          }
        } catch (error) {
          console.error("Error fetching token:", error);
        }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.role_id]);

  onMessageListener()
    .then((payload: any) => {
      setNotification({
        data: payload,
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return <></>;
};
