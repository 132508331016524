import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { SigninSchemaData } from "model/auth";
import { Button, Form } from "react-bootstrap";
import appImages from "components/appImages";
import Password from "components/common/form/input/password";
import Checkbox from "components/common/form/input/checkBox";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "components/signin/signin.scss";
import { useMutation } from "@tanstack/react-query";
import { SignInAPI, getLogo } from "api/authApis";
import SubmitLoader from "components/common/submitLoader/submitLoader";
import {
  getLocalSlug,
  getLoginData,
  saveLoginDataToLocalStorage,
} from "utils/localstorage";
import { SigninSchema } from "utils/schemaValidations/signinSchema";
import { toast } from "react-toastify";
import "components/splash/splash.scss";
import { UseUserState } from "context/userContext";
import { useTranslation } from "react-i18next";
import i18n from "config/i18next";
import Textbox from "components/common/form/input/textBox";

type CompanyDataProps = {
  logo: string;
  keyword: string;
};

const SignIn = () => {
  const { t } = useTranslation(["common", "auth", "validation", "customer"]);
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [show, setShow] = useState<boolean>();
  const [companyData, setCompanyData] = useState<CompanyDataProps | null>(null);
  const { setUserData } = UseUserState();
  const slug = getLocalSlug();

  const { customerName } = useParams();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (customerName && pathname !== "/signin") {
      getCompanyLogo({ keyword: customerName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerName]);
  
  useEffect(() => {
    if(customerName){
      setValue("slug", customerName)
    } else if(slug) {
      setValue("slug", slug)
    }
  }, [customerName, slug])

	const {
		mutate: getCompanyLogo,
		// isLoading: isLogoLoading,
		// error: logoError,
	} = useMutation(getLogo, {
		onSuccess: (data: any) => {		
			if (data?.status === 200) {
        setCompanyData(data.data.result)
				setShow(true)
			}
			if (data?.status === 422) {
			navigate("/404")
      // setShow(true)
      }
		},
		onError: (errorData: any) => {
			navigate("/404")
		},
	});

  const [loginData, setLoginData] = useState<SigninSchemaData | undefined>(
    getLoginData()
  );

  const handleTogglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<SigninSchemaData>({
    resolver: yupResolver(SigninSchema),
  });
  const watchRememberMe = watch("remember_me");

  useEffect(() => {
    if(loginData) {
      setValue("email", loginData?.email)
      setValue("password", loginData?.password)
      setValue("remember_me", loginData?.remember_me)
      setValue("slug", loginData?.slug)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData, reset]);

  const {
    mutate,
    isLoading: isSubmitLoading,
    // error: mutateError,
  } = useMutation(SignInAPI, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        i18n.changeLanguage(data.data.result.language);
        setUserData(data.data.result);
        if(data.data.result.slug) {
          localStorage.setItem("_slug", data.data.result.slug);
        }else {
          localStorage.removeItem("_slug");
        }
        localStorage.setItem("AuthSecToken", data.data.result.token);
        saveLoginDataToLocalStorage(loginData);
        toast.success(data.data.message)
        navigate(`/${data.data.result.slug}/dashboard`);
      }
      if (data?.status === 422) {
        toast.error(data.data.message);
      }
    },
    onError: (errorData: any) => {
      if (typeof errorData === "string") {
        toast.error(errorData);
      } else {
        errorData.forEach((item: any) => {
          setError(item.key, { type: "custom", message: item.msg });
        });
      }
    },
  });

  //Form Submit
  const onSubmit = async (data: SigninSchemaData) => {
    setLoginData(data);
    const payloadData = {...data}
    await mutate(payloadData);
  };

  useEffect(() => {
    setTimeout(() => {
      if (pathname === "/signin" || pathname === "/") {
        setShow(true);
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={`splash-screen ${show ? "hide" : ""}`}>
        <div className="main-logo text-center">
          <img src={appImages.BrandImg} alt="" />
        </div>
        <div className="splash-image">
          <img src={appImages.spalshImg} alt="" />
        </div>
      </div>
      <div className={`signin-wrapper ${show ? "show" : "hide"}`}>
        <div className="main-logo text-center">
          <img
            src={
              companyData && companyData?.logo
                ? companyData?.logo
                : ""
            }
            alt={
              companyData && companyData?.keyword
                ? companyData?.keyword
                : ""
            }
          />
        </div>
        <div className="signin-section  d-flex">
          <img
            className="signin-img d-xl-block d-none"
            src={appImages.loginImg}
            alt=""
          />
          <div className="signin-form">
            <div>
              <div className="text-center">
                <h1>{t("auth:btn_signin")}</h1>
                <p>{t("auth:signin_msg")}</p>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Textbox
                  control={control}
                  type="text"
                  id="slug"
                  name="slug"
                  value=""
                  className="form control"
                  placeholder={t("customer:enter_company_name")}
                  label={t("common:company_name")}
                  errorMessage={errors.slug && t(`validation:${errors.slug?.message}`)}
                  isRequired={true}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  name="email"
                  render={({ field }) => (
                    <Form.Group className={`form-group`}>
                      <Form.Label htmlFor="email">{t("common:email")}</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        autoComplete="on"
                        id="email"
                        value={watch("email") || loginData?.email}
                        placeholder={t("common:enter_email")}
                        onChange={(e: any) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <div className="input-img">
                        <img src={appImages.User} alt="Hide Password" />
                      </div>
                      {errors.email && (
                        <div className="invalid-feedback d-block">
                          {t(`validation:${errors.email.message}`)}
                        </div>
                      )}
                    </Form.Group>
                  )}
                />
                <Password
                  control={control}
                  type="password"
                  id="password"
                  name="password"
                  value=""
                  autoComplete='on'
                  className="form control"
                  placeholder={t("common:enter_password")}
                  label={t("common:password")}
                  errorMessage={errors.password && t(`validation:${errors.password?.message}`)}
                  enableShowPassword={true}
                  isRequired={true}
                  passwordType={passwordType}
                  handleTogglePassword={handleTogglePassword}
                />
                <div className="d-flex justify-content-between">
                  <Checkbox
                    control={control}
                    type="checkbox"
                    id="remember_me"
                    name="remember_me"
                    label={t("auth:remember_me")}
                    value={watchRememberMe}
                    checked={watchRememberMe}
                  />
                  <Link
                    to={`forgot-password`}
                    className="blue-color forgot-password"
                  >
                    {t("auth:forgot_password")}
                  </Link>
                </div>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitLoading}
                  className="w-100"
                >
                  {isSubmitLoading && <SubmitLoader />}
                  {t("auth:btn_signin")}
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <div className="main-logo main-logo-footer text-center p-0">
          <img src={appImages.BrandImg} alt="" />
        </div>
      </div>
    </>
  );
};

export default SignIn;
