import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import "components/forgotPassword/forgotPassword.scss";
import { ForgotPasswordValidaion } from "utils/schemaValidations/signinSchema";
import { ForgotPasswordSchema } from "model/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { ForgotPasswordAPI } from "api/authApis";
import SubmitLoader from "components/common/submitLoader/submitLoader";
import appImages from "components/appImages";
import { useTranslation } from "react-i18next";
import Textbox from "components/common/form/input/textBox";
import { getLocalSlug } from "utils/localstorage";

const ForgotPassword = () => {
  const { t } = useTranslation(["common", "auth", "validation"]);
  const navigate = useNavigate();
  const [currentEmail, setCurrentEmail] = useState<string>();
  const comapanyName = getLocalSlug();
  const { slug } = useParams();

  const {
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ForgotPasswordSchema>({
    resolver: yupResolver(ForgotPasswordValidaion),
  });


  const {
    mutate,
    isLoading: isSubmitLoading,
    // error: mutateError,
  } = useMutation(ForgotPasswordAPI, {
    onSuccess: (data: any) => {
      if (data?.status === 200) {
        toast.success(data.data.message)
      }
      if (data?.status === 422) {
        toast.error(data.data.message)
    }
    },
    onError: (errorData: any) => {
      navigate("/reset-password", { state: { email: currentEmail } });
      if (typeof errorData === "string") {
        toast.error(errorData);
      } else {
        errorData.forEach((item: any) => {
          setError(item.key, { type: "custom", message: item.msg });
        });
      }
    },
  });

  useEffect(() => {
    if(slug){
      setValue("slug", slug)
    } else if(comapanyName) {
      setValue("slug", comapanyName)
    }
  }, [slug, comapanyName])

  const onSubmit = async (data: ForgotPasswordSchema) => {
    setCurrentEmail(data.email);
    const payload = {...data}
    await mutate(payload);
  };

  return (
    <>
      <div className="signin-form d-flex align-items-center justify-content-center h-100">
        <div>
          <div className="text-center">
            <h1>{t("auth:forgot_password")}</h1>
            <p>{t("auth:reset_instructions")}</p>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Textbox
              control={control}
              type="text"
              id="slug"
              name="slug"
              value=""
              className="form control"
              placeholder={t("customer:enter_company_name")}
              label={t("common:company_name")}
              errorMessage={errors.slug && t(`validation:${errors.slug?.message}`)}
              isRequired={true}
            />
            <Controller
              control={control}
              defaultValue=""
              name={"email"}
              render={({ field }) => (
                <Form.Group className={`form-group`}>
                  <Form.Label htmlFor={"email"}>{t("common:email")}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    id="email"
                    placeholder={t("common:enter_email")}
                    onChange={(e: any) => {
                      field.onChange(e.target.value);
                    }}
                  />
                  <div className="input-img">
                    <img src={appImages.User} alt="Hide Password" />
                  </div>
                  {errors.email && (
                    <div className="invalid-feedback d-block">
                      {t(`validation:${errors.email.message}`)}
                    </div>
                  )}
                </Form.Group>
              )}
            />

            <Button
              type="submit"
              variant="primary"
              disabled={isSubmitLoading}
              className="w-100 form-group"
            >
              {isSubmitLoading && <SubmitLoader />}
              {t("auth:send_reset_link")}
            </Button>
            <div className="reset-password text-center">
            {t("auth:remember_password")}? <Link to={`/${slug ?? ""}`}>{t("auth:back_to_login")}</Link>
            </div>
          </Form>
        </div>
      </div>
      {/* <Footer></Footer> */}
    </>
  );
};

export default ForgotPassword;
